import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface CountdownProps {
    channel: {
        description_closed: string | null;
        id: string;
        name: string;
        published_from: string;
        publishes_in: number;
        shorturl: string;
        uri: string;
        url: string;
    };
}

export const Countdown: React.FC<CountdownProps> = (props) => {
    const { channel } = props;
    const calcTimeLeft = (seconds: number | null) => {
        if (!seconds) return null;
        const timeLeft = {
            days: Math.floor(seconds / (60 * 60 * 24)),
            hours: Math.floor((seconds / (60 * 60)) % 24),
            minutes: Math.floor((seconds / 60) % 60),
            seconds: Math.floor(seconds % 60),
        };

        return timeLeft;
    };

    const { t } = useTranslation();

    const [secondsLeft, setSecondsLeft] = useState(channel.publishes_in);
    const [timeLeft, setTimeLeft] = useState(calcTimeLeft(channel.publishes_in));

    useEffect(() => {
        if (secondsLeft === 0) return;

        const countdownTimeout = setTimeout(() => {
            const newSeconds = secondsLeft - 1;
            setSecondsLeft(newSeconds);
            setTimeLeft(calcTimeLeft(newSeconds));
        }, 1000);

        () => {
            clearTimeout(countdownTimeout);
        };
    }, [secondsLeft]);

    const reloadStore = () => {
        window.location.href = location.toString().replace(location.search, '');
    };

    if (!channel.publishes_in) return null;
    if (!timeLeft) return <button onClick={reloadStore}>{t(`modal.fullscreen.channel_willpublish.button`)}</button>;

    return (
        <StyledCountdown>
            {timeLeft.days > 0 && (
                <StyledCountdownBox>
                    <StyledCountdownNumber>{timeLeft.days.toString().padStart(2, '0')}</StyledCountdownNumber>
                    <StyledCountdownLabel>
                        {t('modal.fullscreen.channel_willpublish.countdown.days')}
                    </StyledCountdownLabel>
                </StyledCountdownBox>
            )}
            <StyledCountdownBox>
                <StyledCountdownNumber>{timeLeft.hours.toString().padStart(2, '0')}</StyledCountdownNumber>
                <StyledCountdownLabel>{t('modal.fullscreen.channel_willpublish.countdown.hours')}</StyledCountdownLabel>
            </StyledCountdownBox>
            <StyledCountdownBox>
                <StyledCountdownNumber>{timeLeft.minutes.toString().padStart(2, '0')}</StyledCountdownNumber>
                <StyledCountdownLabel>
                    {t('modal.fullscreen.channel_willpublish.countdown.minutes')}
                </StyledCountdownLabel>
            </StyledCountdownBox>
            <StyledCountdownBox>
                <StyledCountdownNumber>{timeLeft.seconds.toString().padStart(2, '0')}</StyledCountdownNumber>
                <StyledCountdownLabel>
                    {' '}
                    {t('modal.fullscreen.channel_willpublish.countdown.seconds')}
                </StyledCountdownLabel>
            </StyledCountdownBox>
        </StyledCountdown>
    );
};

const StyledCountdown = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
`;

const StyledCountdownBox = styled.div`
    margin-right: 2rem;
    position: relative;
    width: 25%;
    text-align: center;

    &::after {
        content: ':';
        position: absolute;
        top: 0.125rem;
        right: -1.125rem;
        font-size: 2.25rem;
        font-weight: 700;
    }

    &:last-of-type {
        margin-right: 0;

        &::after {
            display: none;
        }
    }
`;

const StyledCountdownNumber = styled.div`
    font-size: 2.75rem;
    font-weight: 700;
`;

const StyledCountdownLabel = styled.div`
    margin-top: 0.25rem;
    font-size: 1.25rem;
    font-weight: 400;
`;
