import React from 'react';
import styled from 'styled-components';

import { Link } from 'components/Link';
import { Icon } from 'components/Icon';

interface BackLinkProps {
    to: string;
    linkText: string;
}

export const BackLink: React.FC<BackLinkProps> = (props) => {
    const { to, linkText } = props;
    return (
        <StyledChangeShowLink to={to}>
            <StyledLinkIcon>
                <Icon name="arrow-left-line" />
            </StyledLinkIcon>
            {linkText}
        </StyledChangeShowLink>
    );
};

const StyledChangeShowLink = styled(Link)`
    margin-bottom: 1.5rem;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
    transition: all 200ms ease;

    &:hover {
        color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
    }
`;

const StyledLinkIcon = styled.span`
    display: inline-block;
    position: relative;
    bottom: -0.125rem;
    margin-right: 0.25rem;
`;
