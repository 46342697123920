import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { EventSquareEdition, FacebookIntegrationCredentials } from 'types/EditionTypes';
import { EventSquareEvent } from 'types/EventTypes';
import { useLocation } from 'react-router-dom';

export const useFBPixel = (event: EventSquareEvent | undefined, edition: EventSquareEdition | undefined) => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        document.addEventListener('Track', handleTrack);
        return () => {
            document.removeEventListener('Track', handleTrack);
        };
    }, [event, edition, initialized]);

    useEffect(() => {
        const fbIntegration = edition?.integrations.find((intergration) => intergration.type === 'facebook_pixel');
        if (!edition || !fbIntegration) {
            setInitialized(false);
        } else {
            const { pixel_id } = fbIntegration.credentials as FacebookIntegrationCredentials;
            ReactPixel.init(pixel_id), null, { autoConfig: true, debug: false };
            setInitialized(true);
        }
    }, [edition]);

    useEffect(() => {
        if (!initialized) return;
        ReactPixel.pageView();
    }, [location, initialized]);

    const handleTrack = (data: any) => {
        if (!initialized) return;
        switch (data.detail.type) {
            case 'AddToCart':
                const category = event?.name + (edition?.name ? ` - ${edition?.name}` : '');
                ReactPixel.track('AddToCart', {
                    content_ids: [data.detail.type_id],
                    content_type: 'product',
                    content_category: category,
                    content_name: data.detail.name,
                    value: data.detail.price,
                    currency: event?.currency,
                });
                break;
            default:
                break;
        }
    };
};
