import { useCallback, useEffect, useState } from 'react';
//import { InitOptions } from 'react-ga4/types/ga4';
import ReactGA from 'react-ga4';
import { EventSquareEdition, GoogleIntegrationCredentials } from 'types/EditionTypes';
import { EventSquareEvent } from 'types/EventTypes';
import { useLocation } from 'react-router-dom';

const GATrackerESQ = 'G-MCSQTFV9JV';

export const useGA = (event: EventSquareEvent | undefined, edition: EventSquareEdition | undefined) => {
    const [GATrackerEdition, setGATrackerEdition] = useState<string | undefined>(undefined);
    const location = useLocation();

    const getActiveTrackers = useCallback(() => {
        return GATrackerEdition ? [GATrackerEdition, GATrackerESQ] : [GATrackerESQ];
    }, [GATrackerEdition]);

    useEffect(() => {
        document.addEventListener('Track', handleTrack);
        return () => {
            document.removeEventListener('Track', handleTrack);
        };
    }, [event, edition]);

    useEffect(() => {
        ReactGA.initialize(GATrackerESQ);

        if (GATrackerEdition) {
            // add edtition tracker to GA
            ReactGA._gtag('config', GATrackerEdition, { send_page_view: false });
        }
    }, [GATrackerEdition]);

    useEffect(() => {
        const GA = edition?.integrations.find((intergration) => intergration.type === 'google_analytics');
        if (!edition || !GA) {
            setGATrackerEdition(undefined);
        } else {
            const { google_tracking_id } = GA.credentials as GoogleIntegrationCredentials;
            setGATrackerEdition(google_tracking_id);
        }
    }, [edition]);

    useEffect(() => {
        // Should be refactored since this runs before the event or edition is loaded, causing to use generic page title
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            location: window.location.href,
            send_to: getActiveTrackers(),
        });
    }, [location]);

    const handleTrack = (data: any) => {
        switch (data.detail.type) {
            case 'AddToCart':
                const category = event?.name + (edition?.name ? ` - ${edition?.name}` : '');
                ReactGA.event('add_to_cart', {
                    items: [
                        {
                            item_id: data.detail.type_id,
                            item_name: data.detail.name,
                            item_category: category,
                        },
                    ],
                    currency: event?.currency,
                    value: data.detail.price,
                    send_to: getActiveTrackers(),
                });
                break;
            case 'TrackLinkClick':
                ReactGA.event('link_click', {
                    place: data.detail.place,
                    link: data.detail.link,
                    send_to: getActiveTrackers(),
                });
                break;
            default:
                break;
        }
    };
};

type LinkType = 'info' | 'share' | 'help';
type LinkPlace = 'mobile_nav' | 'channel_nav' | 'channel_info_full' | 'channel_info_icon';

export const trackLinkClick = (link: LinkType, place: LinkPlace) => {
    document.dispatchEvent(
        new CustomEvent('Track', {
            detail: {
                type: 'TrackLinkClick',
                place: place,
                link: link,
            },
        }),
    );
};
