import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Icon, IconProps } from 'components/Icon';
import { LoaderWithOverlay } from 'components/LoadingSpinner';
import { motion, AnimatePresence } from 'framer-motion';

interface ModalProps {
    // children: JSX.Element[] | JSX.Element | boolean;
    // children: React.ReactNode;
    closeModal: () => void;
    loading?: boolean;
    center?: boolean;
    isVisible?: boolean;
}

export const Modal: React.FC<ModalProps> = (props) => {
    const { children, closeModal, loading, center, isVisible } = props;
    const modalBoxEl = useRef<HTMLDivElement>(null);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    };

    const handleOutsideClick = (e: React.MouseEvent<HTMLElement>) => {
        if (modalBoxEl.current && !modalBoxEl.current.contains(e.target as Element)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (!isVisible) return;
        document.body.style.overflow = 'hidden';
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.style.overflow = 'auto';
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isVisible]);

    return (
        <AnimatePresence>
            {isVisible && (
                <StyledModalWrapper
                    $centeredtext={center}
                    onClick={handleOutsideClick}
                    key="modal"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { ease: 'easeOut', duration: 0.2, when: 'beforeChildren' } }}
                    exit={{ opacity: 0, transition: { ease: 'easeIn', duration: 0.2, when: 'afterChildren' } }}
                >
                    <StyledModal
                        ref={modalBoxEl}
                        initial={{ y: 300, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { ease: 'easeOut', duration: 0.4 } }}
                        exit={{ y: 300, opacity: 0, transition: { ease: 'easeIn', duration: 0.4 } }}
                    >
                        <StyledCloseX onClick={closeModal}>
                            <Icon name="close-fill" />
                        </StyledCloseX>
                        {children}
                        {loading && <LoaderWithOverlay />}
                    </StyledModal>
                </StyledModalWrapper>
            )}
        </AnimatePresence>
    );
};

interface ModalIconProps extends IconProps {
    warning?: boolean;
}

export const ModalIcon: React.FC<ModalIconProps> = (props) => {
    const { name, warning } = props;
    return (
        <StyledModalIcon warning={warning}>
            <Icon name={name} />
        </StyledModalIcon>
    );
};

export const StyledModalIcon = styled.div<{ warning?: boolean }>`
    font-size: 2.75rem;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 0.75rem;

    ${(props) =>
        props.warning &&
        css`
            color: ${(props) => props.theme.helpers.getWarningColor(props.theme.colors.primary)};
        `}
`;

const StyledModalWrapper = styled(motion.div)<{ $centeredtext?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 1rem;
    z-index: 900;
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
    background-color: ${(props) => props.theme.helpers.transparancy(props.theme.colors.fontDark, 0.4)};

    ${(props) =>
        props.$centeredtext &&
        css`
            text-align: center;
        `}
`;

const StyledModal = styled(motion.div)`
    margin: auto;
    position: relative;
    width: 90%;
    max-width: 22.5rem;
    background-color: #ffffff;
    padding: 3.5rem 2rem 2rem;
    border-radius: 0.25rem;
    box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.fontDark)};
    color: ${(props) => props.theme.colors.fontMedium};
`;

const StyledCloseX = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;

    color: ${(props) => props.theme.colors.fontMedium};
    font-size: 2rem;
`;

export const ModalTitle = styled.h1<{ warning?: boolean }>`
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: ${(props) => props.theme.colors.primary};

    ${(props) =>
        props.warning &&
        css`
            color: ${(props) => props.theme.helpers.getWarningColor(props.theme.colors.primary)};
        `}
`;

export const ModalText = styled.p`
    line-height: 1.25;
    margin-bottom: 2rem;
`;

interface ModalErrorProps {
    onClick: () => void;
}

export const ModalError: React.FC<ModalErrorProps> = (props) => {
    const { children, onClick } = props;
    return <StyledModalError onClick={onClick}>{children}</StyledModalError>;
};

const StyledModalError = styled.div`
    background-color: ${(props) => props.theme.helpers.getWarningColor(props.theme.colors.primary)};
    color: #fff;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0.75rem;
    margin-bottom: 1rem;
    cursor: pointer;
`;
