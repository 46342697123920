import axios from 'axios';
import React from 'react';
import styled from 'styled-components';

import { CartDeal } from 'types/CartTypes';

import { useAppDispatch } from 'redux/hooks';
import { fetchCart } from 'redux/slices/storeSlice';

import { Icon } from './Icon';
import { useParams } from 'react-router-dom';
import { devLog } from 'lib/dev';

interface DealsProps {
    deals: CartDeal[];
    cartId: string;
}

export const Deals: React.FC<DealsProps> = (props) => {
    const { deals, cartId } = props;
    const { lang } = useParams();

    if (!lang) return null;

    return (
        <StyledDealsList>
            {deals.map((deal) => (
                <li key={deal.id}>
                    <Deal deal={deal} cartId={cartId} lang={lang} />
                </li>
            ))}
        </StyledDealsList>
    );
};

const StyledDealsList = styled.ul`
    margin-bottom: 2rem;
`;

interface DealProps {
    deal: CartDeal;
    cartId: string;
    lang: string;
}

export const Deal: React.FC<DealProps> = (props) => {
    const { deal, cartId, lang } = props;
    const dispatch = useAppDispatch();

    const removeCoupon = async (code: string) => {
        try {
            const params = {
                cart: cartId,
                code: code,
            };
            const response = await axios.delete('/api/coupon', { params, timeout: 30000 });

            if (response.data.success) {
                dispatch(fetchCart(lang));
            }
        } catch (error) {
            devLog(error);
        }
    };

    return (
        <StyledDeal>
            {deal.times_fulfilled > 0 && (
                <StyledActiveCorner>
                    <span>
                        <Icon name="check-fill" />
                    </span>
                </StyledActiveCorner>
            )}
            <div className="deal-icon">
                <Icon name="coupon-line" />
            </div>
            <div>
                <StyledDealTitle>{deal.name}</StyledDealTitle>
                <StyledDealDescription>{deal.description}</StyledDealDescription>
                {deal.coupon && (
                    <StyledDealCoupon>
                        <StyledDealCouponCode>{deal.coupon}</StyledDealCouponCode>
                        <StyledDealCouponRemove onClick={() => removeCoupon(deal.coupon)}>
                            wissen
                        </StyledDealCouponRemove>
                    </StyledDealCoupon>
                )}
            </div>
        </StyledDeal>
    );
};

const StyledDeal = styled.div`
    position: relative;
    width: 100%;
    border-radius: 0.25rem;
    border: 1px solid ${(props) => props.theme.colors.lines};
    padding: 1rem 0.5rem 1.125rem;
    margin-bottom: 1rem;
    overflow: hidden;
    display: grid;
    grid-template-columns: 3.5rem 1fr;
    gap: 0.5rem;
    align-items: center;

    div.deal-icon {
        font-size: 2.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.colors.fontMedium};
    }
`;

const StyledActiveCorner = styled.div`
    width: 0;
    height: 0;
    border-top: 36px solid ${(props) => props.theme.colors.primary};
    border-right: 36px solid transparent;
    position: absolute;
    left: -1px;
    top: -1px;

    span {
        position: absolute;
        top: -34px;
        width: 20px;
        right: -23px;
        text-align: center;
        font-size: 1.2em;
        display: block;
        color: #fff;
    }
`;

const StyledDealTitle = styled.h6`
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: ${(props) => props.theme.colors.fontDark};
`;

const StyledDealDescription = styled.p`
    font-size: 0.875rem;

    color: ${(props) => props.theme.colors.fontMedium};
`;

const StyledDealCoupon = styled.div`
    margin-top: 0.5rem;
`;

const StyledDealCouponCode = styled.span`
    display: inline-block;
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.fontDark};
    font-family: monospace;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-right: 0.75rem;
`;

const StyledDealCouponRemove = styled.span`
    color: ${(props) => props.theme.colors.primary};
    font-size: 0.875rem;
    font-weight: 700;
    cursor: pointer;
`;
