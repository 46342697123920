import { DateTime } from 'luxon';
import { EventSquareCart, CartTicketType } from 'types/CartTypes';
import { Currency } from 'types/CurrencyTypes';
import { EventSquareEdition, TicketType, TypeModeType } from 'types/EditionTypes';

import { DateTime as DateTimeType } from 'types/EventTypes';

export const Test = () => {
    return 'this is a test';
};

export const areDatesLessThanDayApart = (start: DateTimeType, end: DateTimeType): boolean => {
    if (!start || !end) return false;
    const dtStart = DateTime.fromSQL(start);
    const dtEnd = DateTime.fromSQL(end);

    // if differnce between start & end is smaller dan 24h (86400 seconds) return true
    const diffInSeconds = dtEnd.diff(dtStart, 'seconds').toObject().seconds;

    if (typeof diffInSeconds !== 'undefined' && diffInSeconds < 86400) return true;
    return false;
};

export const formatCountryInLocale = (country_code: string, lang: string | undefined) => {
    const locale = lang || navigator.language.slice(0, 2);
    return new Intl.DisplayNames([locale], { type: 'region' }).of(country_code);
};

export const formatDate = (date: DateTimeType | null | undefined, hideHours: boolean, lang: string | undefined) => {
    if (!date) return null;

    const locale = lang || navigator.language.slice(0, 2);

    const dateFormat: DateFormat = {
        weekday: undefined,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
    };

    if (hideHours) {
        dateFormat['hour'] = undefined;
        dateFormat['minute'] = undefined;
    }
    const dt = DateTime.fromSQL(date);
    const dtLocale = dt.setLocale(locale).toLocaleString(dateFormat);

    return dtLocale[0].toUpperCase() + dtLocale.slice(1);
};

interface DateFormat {
    weekday?: 'long' | 'short' | undefined;
    year: 'numeric' | '2-digit' | undefined;
    month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined;
    day: 'numeric' | '2-digit' | undefined;
    hour?: 'numeric' | '2-digit' | undefined;
    minute?: 'numeric' | '2-digit' | undefined;
}

export const formatTimeSimple = (date: DateTimeType | null | undefined, lang: string | undefined) => {
    if (!date) return null;

    const locale = lang || navigator.language.slice(0, 2);

    const dt = DateTime.fromSQL(date);
    return dt.setLocale(locale).toLocaleString(DateTime.TIME_SIMPLE);
};

export const getQuantity = (
    type: TicketType | CartTicketType | undefined,
    cart: EventSquareCart | undefined,
    showId: string | undefined = undefined,
) => {
    if (!type || !cart) return 0;
    const typeId = type.id;
    let filteredCardTypes = cart.items.types.filter((cartType: any) => cartType.id === typeId && !cartType.voucher);
    if (showId) {
        filteredCardTypes = filteredCardTypes.filter((cartType: any) => cartType.show.id === showId);
    }
    if (filteredCardTypes.length === 0) return 0;
    return filteredCardTypes[0].quantity;
};

export const getQuantityInShow = (showId: string | undefined, cart: EventSquareCart | undefined) => {
    if (!showId || !cart) return 0;

    const typesFromShow = cart.items.types.filter(
        (cartType: CartTicketType) => cartType.show?.id === showId && !cartType.voucher,
    );

    if (!typesFromShow.length) return 0;

    const quantity = typesFromShow.reduce((value, currentType: CartTicketType) => {
        return value + currentType.quantity;
    }, 0);

    return quantity;
};

export const getCartQuantity = (cart: EventSquareCart | undefined) => {
    if (!cart) return 0;
    const quantity = cart.items.types.reduce((prevValue: number, type: CartTicketType) => {
        return prevValue + type.quantity;
    }, 0);
    return quantity;
};

export const getActiveCouponDeal = (cart: EventSquareCart | undefined) => {
    if (!cart) return null;
    return cart.deals.find((deal) => deal.coupon);
};

export const formatCurrency = (number: number, lang: string | undefined, currency: Currency) => {
    const langToFormat = lang ?? 'nl';
    return number.toLocaleString(langToFormat, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol',
    });
};

export const getYearNow = () => {
    const dt = DateTime.now();
    return dt.toLocaleString({ year: 'numeric' });
};

export const getDiscountPrice = (type: TicketType | CartTicketType | undefined, cart: EventSquareCart | undefined) => {
    if (!type || !cart || !type.price) return 0;
    const originalPrice = type.price;
    const typeId = type.id;

    const deals = cart.deals;

    const discountPrice = deals.reduce((price, deal) => {
        if (deal.times_fulfilled === 0) return price;

        const bestRewardsPrice = deal.rewards.reduce((rewardPrice, reward) => {
            if ((reward.types && !reward.types.includes(typeId)) || reward.reward !== 'order_discount')
                return rewardPrice;

            let tempPrice = rewardPrice;
            if (reward.discount_type === 'fixed') {
                tempPrice = rewardPrice - +reward.discount_amount;
            }
            if (reward.discount_type === 'percentage') {
                tempPrice = rewardPrice * ((100 - +reward.discount_amount) / 100);
            }

            tempPrice = Math.max(0, tempPrice);

            return tempPrice < rewardPrice ? tempPrice : rewardPrice;
        }, price);

        return bestRewardsPrice < price ? bestRewardsPrice : price;
    }, originalPrice);

    return discountPrice;
};

export const formatTimer = (numberOfSeconds: number): string => {
    const days = Math.floor(numberOfSeconds / 86400);
    const hours = Math.floor((numberOfSeconds - days * 86400) / 3600);
    const min = Math.floor((numberOfSeconds - days * 86400 - hours * 3600) / 60);
    const sec = numberOfSeconds - days * 86400 - hours * 3600 - min * 60;

    const hoursVis = hours.toString().padStart(2, '0');
    const minVis = min.toString().padStart(2, '0');
    const secVis = sec.toString().padStart(2, '0');

    let response: string = minVis + ':' + secVis;
    if (hours) response = hoursVis + ':' + response;
    if (days) response = days.toString() + ':' + response;

    return response;
};

export const getTypeMode = (edition: EventSquareEdition | undefined): TypeModeType => {
    if (!edition) return 'disabled';

    const typemode = edition.channel.type_mode;
    const types = edition.channel.types;

    if (typemode === 'disabled' || !types) return 'disabled';

    const tickets = types.filter((type) => type.type === 'ticket');
    const vouchers = types.filter((type) => type.type === 'voucher');

    if (typemode === 'auto' || !typemode) {
        return tickets.length > 6 && vouchers.length > 4 ? 'split' : 'disabled';
    }

    if (typemode === 'split') {
        return vouchers.length > 0 ? 'split' : 'disabled';
    }

    return typemode ?? 'disabled';
};
