import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';

interface LangSwitchProps {
    languages: string[];
}

export const LangSwitch: React.FC<LangSwitchProps> = (props) => {
    const { languages } = props;

    const { lang } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const langSwitchRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    const handleLangChange = (selectedLang: string) => {
        if (lang === selectedLang) {
            setOpen(false);
            return;
        }

        const pathArr = location.pathname.split('/');
        pathArr[1] = selectedLang;
        i18n.changeLanguage(selectedLang);
        navigate(pathArr.join('/'));
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setOpen(false);
        }
    };

    const handleOutsideClick = (ev: MouseEvent) => {
        if (langSwitchRef.current && !langSwitchRef.current.contains(ev.target as Element)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (!open) return;

        document.addEventListener('keydown', handleKeyDown);
        document.body.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, [open]);

    const getFullLanguageName = (language: string) => {
        switch (language) {
            case 'en':
                return 'English';
            case 'es':
                return 'Español';
            case 'nl':
                return 'Nederlands';
            case 'fr':
                return 'Français';
            case 'de':
                return 'Deutsch';
            case 'it':
                return 'Italiano';
            case 'fi':
                return 'Suomi';
            case 'da':
                return 'Dansk';
            case 'sv':
                return 'Svenska';
            case 'nb':
                return 'Norsk Bokmål';
        }
    };

    if (!languages || languages.length <= 1) return null;

    return (
        <StyledLangSwitch ref={langSwitchRef}>
            <StyledDropDownButton open={open} onClick={handleToggle}>
                <span className="icon-global">
                    <Icon name="global-line" />
                </span>
                <span className="active-lang">{lang}</span>
                <span className="icon-dropdown">
                    <Icon name="arrow-down-s-line" />
                </span>
            </StyledDropDownButton>
            <StyledDropDownList open={open}>
                {languages.map((language) => (
                    <StyledDropDownItem
                        key={language}
                        active={language === lang}
                        onClick={() => handleLangChange(language)}
                    >
                        {getFullLanguageName(language)}
                        {language === lang && (
                            <StyledCheck>
                                <Icon name="check-fill" />
                            </StyledCheck>
                        )}
                    </StyledDropDownItem>
                ))}
            </StyledDropDownList>
        </StyledLangSwitch>
    );
};

const StyledLangSwitch = styled.div`
    margin-left: auto;
    margin-right: -0.25rem;
    display: flex;
    position: relative;
`;

const StyledDropDownButton = styled.button<{ open: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background: transparent;
    padding: 0.5rem;
    padding-right: 0.25rem;
    cursor: pointer;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.colors.fontMedium};

    .icon-global,
    .icon-dropdown {
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        color: ${(props) => props.theme.colors.fontLight};
    }

    .icon-global {
        margin-right: 0.25rem;
    }

    .icon-dropdown {
        margin-left: 0.25rem;
    }

    /* .active-lang {
        position: relative;
        top: 1px;
    } */
`;

const StyledDropDownList = styled.ul<{ open: boolean }>`
    display: ${(props) => (props.open ? 'block' : 'none')};
    list-style: none;
    color: ${(props) => props.theme.colors.fontMedium};
    background-color: #fff;
    box-shadow: ${(props) => props.theme.grayBoxShadow};
    border-radius: 0.25rem;
    position: absolute;
    top: calc(100% + 0.125rem);
    right: -0.125rem;
    overflow: hidden;
    z-index: 110;
    border: 1px solid ${(props) => props.theme.colors.lines};
`;

const StyledDropDownItem = styled.li<{ active: boolean }>`
    display: block;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    transition: all 300ms ease;

    &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.colors.lines};
    }

    ${(props) =>
        props.active &&
        css`
            font-weight: 800;
            pointer-events: none;
        `}

    &:hover {
        font-weight: 700;
    }

    &:last-child {
    }
`;

const StyledCheck = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;
