import React from 'react';
import symbols from '../symbols/symbol-defs.svg';
// const symbols = require('../symbols/symbol-defs.svg') as string;

export interface IconProps {
    name: string;
    alt?: string;
}

export const Icon: React.FC<IconProps> = (props) => {
    const { name, alt } = props;
    return (
        <svg className={`icon icon-${name}`} role="img">
            {!!alt && <title>{alt}</title>}
            {/* <use xlinkHref={`/icons/symbol-defs.svg?v=${process.env.REACT_APP_VERSION}#icon-${name}`} /> */}
            <use xlinkHref={`${symbols}?v=${process.env.REACT_APP_VERSION}#icon-${name}`} />
        </svg>
    );
};
