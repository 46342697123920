import React, { useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import { EventSquareEvent } from 'types/EventTypes';

import { formatDate } from 'lib/helpers';

import { Icon } from 'components/Icon';
import { Link } from 'components/Link';
import { PasswordProtected } from 'components/PasswordProtected';
import { Countdown } from 'components/Countdown';
import { useNavigate, useParams } from 'react-router-dom';

interface FullScreenErrorModalProps {
    editionError: any;
    event: EventSquareEvent | undefined;
    search?: string;
}

export const FullScreenError: React.FC<FullScreenErrorModalProps> = (props) => {
    const { editionError, event, search } = props;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { lang } = useParams();
    const cookies = new Cookies();
    const cartCookie = cookies.get('cart');
    const startOverErrors = ['cart_locked', 'cart_not_found', 'queue_expired', 'queue_granted', 'queue_not_found'];

    useEffect(() => {
        if (shouldStartOver()) {
            startOver();
            return;
        }
    }, [editionError]);

    const shouldStartOver = () => {
        if (!editionError) return false;
        return startOverErrors.includes(editionError.error);
    };

    const startOver = () => {
        // Remove cart cookie
        cookies.remove('cart', { path: '/' });
        // Redirect to current url without search parameters
        window.location.href = location.toString().replace(location.search, '');
    };

    if (!editionError || !event || shouldStartOver()) return null;

    const goToCookieEdition = () => {
        if (!cartCookie) {
            startOver();
            return;
        }
        const { edition, channel } = cartCookie;
        const editionUrl = `/${lang}/${edition}/${channel}${search ? search : ''}`;
        navigate(editionUrl);
    };

    const { error, edition } = editionError;

    const getIcon = () => {
        switch (error) {
            case 'channel_closed':
                return 'moon-clear-line';
            case 'channel_willpublish':
                return 'time-line';
            case 'password_protected':
            case 'authentication_required':
            case 'channel_authentication_required':
                return 'shield-keyhole-line';
            case 'different_edition':
                return 'information-line';
            default:
                return 'emotion-sad-line';
        }
    };

    const getBackgroundColor = () => {
        switch (error) {
            case 'channel_willpublish':
            case 'channel_closed':
            case 'password_protected':
                return edition?.branding?.color ?? '#068cfc';
            default:
                return '#FC5958';
        }
    };

    const getTitle = () => {
        if (error === 'channel_closed') {
            return event?.name?.trim() ?? t('modal.fullscreen.channel_closed.title');
        }
        if (error === 'channel_willpublish') {
            return event?.name?.trim() ?? t('modal.fullscreen.channel_willpublish.title');
        }
        if (error === 'validation' || error === 'invalid_authentication') {
            return t(`modal.fullscreen.unexpected_error.title`);
        }
        return t(`modal.fullscreen.${error}.title`);
    };

    const getSubTitle = () => {
        if (error === 'channel_closed' || error === 'channel_willpublish') {
            return edition?.name?.trim() ?? '';
        }

        return '';
    };

    const getLead = () => {
        if (error === 'channel_closed') {
            if (event.editions.length === 1) {
                return t('modal.fullscreen.store_closed.p_1', {
                    eventEdition: event.editions[0].name
                        ? `${event.name} ${event.editions[0].name}`
                        : t('modal.fullscreen.store_closed.the_first_edition'),
                });
            }

            if (event.editions.length > 1) {
                return t('modal.fullscreen.store_closed.p_2');
            }
            return t('modal.fullscreen.store_closed.p_3');
        }

        if (error === 'channel_willpublish') {
            return t('modal.fullscreen.channel_willpublish.lead', {
                publishedFrom: formatDate(editionError.channel.published_from, false, i18n.language),
            });
        }

        if (error === 'validation' || error === 'invalid_authentication') {
            return t(`modal.fullscreen.unexpected_error.lead`);
        }

        return t(`modal.fullscreen.${error}.lead`);
    };

    const getOtherEditionUrl = () => {
        if (event.editions.length === 1) {
            return '/' + event.languages[0] + '/' + event.editions[0].uri;
        }
        if (event.editions.length > 1) {
            return '/' + event.languages[0];
        }
        return '';
    };

    const showStartOverButton = () => {
        // Set errors in array where the start over button should not be visible
        return ![
            'channel_closed',
            'channel_willpublish',
            'authentication_required',
            'channel_authentication_required',
            'password_protected',
            'different_edition',
            'unexpected_error',
            'validation',
            'invalid_authentication',
            'channel_unavailable',
        ].includes(error);
    };

    return (
        <StyledWrapper>
            <StyledFullScreen backgroundColor={getBackgroundColor()}>
                <StyledInner>
                    <StyledIcon>
                        <Icon name={getIcon()} />
                    </StyledIcon>
                    <StyledTitle>{getTitle()}</StyledTitle>
                    {getSubTitle() && <StyledSubTitle>{getSubTitle()}</StyledSubTitle>}
                    <StyledParagraph>{getLead()}</StyledParagraph>
                    <StyledAction>
                        {showStartOverButton() && (
                            <button onClick={startOver}>{t(`modal.fullscreen.${error}.button`)}</button>
                        )}
                        {(error === 'channel_closed' || error === 'channel_unavailable') &&
                            event.editions.length > 0 && (
                                <Link to={getOtherEditionUrl()}>
                                    {event.editions.length === 1
                                        ? event.editions[0].name
                                            ? `${event.name} ${event.editions[0].name}`
                                            : t('modal.fullscreen.store_closed.go_to_first_edition')
                                        : t('modal.fullscreen.store_closed.view_other_editions')}
                                </Link>
                            )}
                        {error === 'password_protected' && <PasswordProtected />}
                        {error === 'channel_willpublish' && <Countdown channel={editionError.channel} />}
                        {error === 'different_edition' && (
                            <>
                                <button onClick={goToCookieEdition}>
                                    {t('modal.fullscreen.different_edition.go_back')}
                                </button>
                                <button className="ghost" onClick={startOver}>
                                    {t('modal.fullscreen.different_edition.new_session')}
                                </button>
                            </>
                        )}
                    </StyledAction>
                    <StyledESQLogo>
                        <a href="https://eventsquare.co" target="_blank" rel="noreferrer">
                            <img src="/esq-logo-white.svg" alt="EventSquare" />
                        </a>
                    </StyledESQLogo>
                </StyledInner>
            </StyledFullScreen>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    position: relative;
    height: 100vh;
    min-height: 37.5rem;
`;
interface FullScreenProps {
    backgroundColor: string;
}

const StyledFullScreen = styled.div<FullScreenProps>`
    position: absolute;
    z-index: 900;
    min-height: 600px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.backgroundColor};
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem;
`;

const StyledInner = styled.div`
    width: 100%;
    max-width: 32rem;
    margin-top: auto;
    margin-bottom: auto;
`;

const StyledIcon = styled.div`
    font-size: 4.5rem;
    margin-bottom: 2.5rem;

    @media (max-width: 57em) {
        margin-bottom: 1.5rem;
    }
`;

const StyledTitle = styled.h1`
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;

    @media (max-width: 57em) {
        font-size: 2.25rem;
    }
`;

const StyledSubTitle = styled.h2`
    font-size: 2rem;
    font-weight: 600;
    line-height: 1;

    @media (max-width: 57em) {
        font-size: 1.75rem;
    }
`;

const StyledParagraph = styled.p`
    margin-top: 2.5rem;
    font-size: 1.5rem;
    line-height: 1.2;

    @media (max-width: 57em) {
        font-size: 1.125rem;
        margin-top: 1.5rem;
    }
`;

const StyledAction = styled.div`
    margin-top: 2.5rem;

    a,
    button {
        display: inline-block;
        font-size: 1.25rem;
        font-family: inherit;
        border-radius: 0.25rem;
        border: 1px solid #fff;
        background-color: transparent;
        text-decoration: none;
        color: #fff;
        padding: 1rem 2rem;
        cursor: pointer;
        opacity: 1;
        margin: 0.5rem;

        &:hover {
            color: #fff;
            opacity: 0.8;
        }

        &.ghost {
            border: none;
            margin-top: 1rem;
        }
    }

    @media (max-width: 57em) {
        margin-top: 1.5rem;

        a,
        button {
            font-size: 1.125rem;
        }
    }
`;

const StyledESQLogo = styled.div`
    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;

    a {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        max-width: 12.5rem;

        @media (max-width: 57em) {
            max-width: 10rem;
        }
    }
`;
