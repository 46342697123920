import React, { useState } from 'react';
import {
    EmailShareButton,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, ModalTitle, ModalText, ModalIcon } from 'components/modals/Modal';
import { Icon } from 'components/Icon';

interface ShareModalProps {
    showModal: boolean;
    closeModal: () => void;
    shareLink: string;
}

export const ShareModal: React.FC<ShareModalProps> = (props) => {
    const { showModal, closeModal, shareLink } = props;
    const [showCopiedToClipboard, setShowCopiedToClipboard] = useState<boolean>(false);
    const { t } = useTranslation();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareLink);
        setShowCopiedToClipboard(true);
        setTimeout(() => setShowCopiedToClipboard(false), 1000);
    };

    return (
        <Modal closeModal={closeModal} center isVisible={showModal}>
            <ModalIcon name="share-box-fill" />
            <ModalTitle>{t('modal.share.title')}</ModalTitle>
            <ModalText>{t('modal.share.lead')}</ModalText>
            <StyledShareButtonGrid>
                <FacebookShareButton url={shareLink} resetButtonStyle>
                    <StyledShareButton>
                        <Icon name="facebook-fill" />
                    </StyledShareButton>
                </FacebookShareButton>
                <LinkedinShareButton url={shareLink} resetButtonStyle>
                    <StyledShareButton>
                        <Icon name="linkedin-fill" />
                    </StyledShareButton>
                </LinkedinShareButton>
                <TwitterShareButton url={shareLink} resetButtonStyle>
                    <StyledShareButton>
                        <Icon name="twitter-fill" />
                    </StyledShareButton>
                </TwitterShareButton>
                <WhatsappShareButton url={shareLink} resetButtonStyle>
                    <StyledShareButton>
                        <Icon name="whatsapp-line" />
                    </StyledShareButton>
                </WhatsappShareButton>
                <FacebookMessengerShareButton url={shareLink} resetButtonStyle appId="1051239362477781">
                    <StyledShareButton>
                        <Icon name="messenger-line" />
                    </StyledShareButton>
                </FacebookMessengerShareButton>
                <EmailShareButton url={shareLink} resetButtonStyle>
                    <StyledShareButton>
                        <Icon name="mail-line" />
                    </StyledShareButton>
                </EmailShareButton>
            </StyledShareButtonGrid>
            <ModalText>{t('modal.share.copy_link')}</ModalText>
            <StyledShareLink>
                <StyledInput value={shareLink} readOnly />
                <StyledButton onClick={copyToClipboard}>
                    <Icon name="file-copy-line" />
                    {showCopiedToClipboard && (
                        <StyledCopiedConfirmation>
                            <p>{t('modal.share.copied')}</p>
                        </StyledCopiedConfirmation>
                    )}
                </StyledButton>
            </StyledShareLink>
        </Modal>
    );
};

const StyledShareButtonGrid = styled.div`
    display: grid;
    /* grid-template-columns: repeat(3, minmax(calc(calc(100% - 4rem) / 3), 3.5rem)); */
    grid-template-columns: repeat(3, 4.5rem);
    grid-template-rows: repeat(2, 4.5rem);
    justify-content: space-around;
    gap: 1rem;
    padding: 0 1rem;
    margin-bottom: 2rem;

    > button {
        box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.primary)};
        border-radius: 50% !important;
    }
`;

const StyledShareButton = styled.div`
    border-radius: 50%;
    font-size: 2rem;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease;

    &:hover {
        color: #fff;
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

const StyledShareLink = styled.div`
    border-radius: 0.25rem;
    border: 1px solid ${(props) => props.theme.colors.lines};
    padding: 0.75rem;
    display: grid;
    grid-template-columns: 1fr 1.5rem;
    gap: 1rem;
    margin-top: -0.5rem;
`;

const StyledInput = styled.input`
    border: none;
    font-size: 1rem;
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: 400;
    color: ${(props) => props.theme.colors.fontMedium};

    &:focus {
        outline: none;
    }
`;

const StyledButton = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 1.5rem;
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    position: relative;

    &:hover {
        color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
    }
`;

const StyledCopiedConfirmation = styled.div`
    background-color: ${(props) => props.theme.colors.fontMedium};
    font-weight: 600;
    color: #fff;
    padding: 0.25rem 0.5rem;
    display: inline-block;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    position: absolute;
    bottom: calc(100% + 0.5rem);
    right: -0.375rem;

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        right: 0.75rem;
        width: 0;
        height: 0;
        border-top: 0.25rem solid ${(props) => props.theme.colors.fontMedium};
        border-left: 0.25rem solid transparent;
        border-right: 0.25rem solid transparent;
    }
`;
