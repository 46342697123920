import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'redux/hooks';

import { Markdown } from 'components/Markdown';
import { Icon } from 'components/Icon';
import { OrganisationInfo } from 'components/OrganisationInfo';
import { VenueInfo } from 'components/VenueInfo';

export const Info = () => {
    const { event, edition } = useAppSelector((state) => {
        return {
            event: state.store.event,
            edition: state.store.edition,
        };
    });

    const { lang } = useParams();

    if (!event || !edition) return null;

    const description = edition.channel.description ?? edition.description;

    if (!description && !edition.venue) return <EmptyDescription />;

    return (
        <>
            {description && <Markdown>{description}</Markdown>}
            {edition.venue && <VenueInfo venue={edition.venue} lang={lang} />}
            <OrganisationInfo organisation={event.organisation} />
        </>
    );
};

const EmptyDescription = () => {
    const { t } = useTranslation();
    return (
        <StyledEmptyDescription>
            <StyledIcon>
                <Icon name="emotion-sad-line" />
            </StyledIcon>
            <StyledTitle>{t('info.empty_title')}</StyledTitle>
            <StyledText>{t('info.empty_lead')}</StyledText>
        </StyledEmptyDescription>
    );
};

const StyledEmptyDescription = styled.div`
    text-align: center;
    color: ${(props) => props.theme.colors.fontDark};
    height: 100%;
`;

const StyledIcon = styled.div`
    font-size: 2.75rem;
    margin-bottom: 0.75rem;
    color: ${(props) => props.theme.colors.primary};
`;

const StyledTitle = styled.h3`
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
`;

const StyledText = styled.p``;
