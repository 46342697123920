import React from 'react';
import styled, { css } from 'styled-components';
import { ClassificationTypeNames } from 'typescript';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';
interface ButtonProps {
    type?: 'button' | 'submit' | 'reset' | undefined;
    buttonType?: 'primary' | 'secondary' | 'edit' | 'ghost' | 'warning' | 'text' | 'withGrayShadow' | 'white';
    fullWidth?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    className?: ClassificationTypeNames;
    disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = (props) => {
    const { children, buttonType, fullWidth, onClick, className, type = 'button', disabled } = props;
    return (
        <StyledButton
            fullWidth={fullWidth}
            buttonType={buttonType}
            onClick={onClick}
            className={className}
            type={type}
            disabled={disabled}
        >
            {children}
        </StyledButton>
    );
};

interface CartButtonProps {
    remainingTime: string | undefined;
    cartQuantity: number | undefined;
    goToPage: 'shop' | 'extras' | 'cart' | 'checkout';
}

export const CartButton: React.FC<CartButtonProps> = (props) => {
    const { remainingTime, cartQuantity, goToPage } = props;
    const { t } = useTranslation();

    return (
        <StyledCartButton>
            {remainingTime && (
                <span className="timer">
                    <div className="clock">
                        <Icon name="time-line" />
                    </div>
                    {remainingTime}
                </span>
            )}
            {goToPage === 'shop' && <span className="text">{t('buttons.to_shop')}</span>}
            {goToPage === 'extras' && <span className="text">{t('buttons.to_extras')}</span>}
            {goToPage === 'cart' && (
                <span className="text">
                    {t('buttons.to_cart')} {!!cartQuantity && <span className="cart">{cartQuantity}</span>}
                </span>
            )}
            {goToPage === 'checkout' && <span className="text">{t('buttons.to_checkout')}</span>}
        </StyledCartButton>
    );
};

const StyledButton = styled.button<ButtonProps>`
    display: block;
    font-size: 1rem;
    font-family: inherit;
    border-radius: 0.25rem;
    font-weight: 600;
    padding: 1rem;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.primary)};
    color: #fff;
    border: none;
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 300ms ease;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 1)};
    }

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
        `}

    ${(props) =>
        props.buttonType === 'secondary' &&
        css`
            color: ${(props) => props.theme.colors.primary};
            background-color: #fff;

            &:hover {
                background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -4.5)};
            }
        `}

    ${(props) =>
        props.buttonType === 'edit' &&
        css`
            padding: 0.5rem 1rem;
            font-weight: 600;
            font-size: 1rem;
        `}

    ${(props) =>
        props.buttonType === 'ghost' &&
        css`
            background-color: #fff;
            box-shadow: none;
            color: ${(props) => props.theme.colors.primary};
            border: 1px solid ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -1)};
            padding: 0.5rem 1rem;

            &:hover {
                background-color: #fff;
                color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -1)};
            }
        `}

    ${(props) =>
        props.buttonType === 'warning' &&
        css`
            background-color: ${(props) => props.theme.helpers.getWarningColor(props.theme.colors.primary)};
            box-shadow: ${(props) =>
                props.theme.helpers.boxShadow(props.theme.helpers.getWarningColor(props.theme.colors.primary))};
            color: #fff;

            &:hover {
                background-color: ${(props) =>
                    props.theme.helpers.shades(props.theme.helpers.getWarningColor(props.theme.colors.primary), 1)};
            }
        `}

    ${(props) =>
        props.buttonType === 'text' &&
        css`
            background: none;
            box-shadow: none;
            color: ${(props) => props.theme.colors.primary};

            &:hover {
                color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
                background: none;
            }
        `}

        ${(props) =>
        props.buttonType === 'withGrayShadow' &&
        css`
            background: none;
            box-shadow: ${props.theme.grayBoxShadow};
            color: ${(props) => props.theme.colors.primary};

            &:hover {
                color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 1)};
                background: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -4)};
            }
        `}
    
    ${(props) =>
        props.disabled &&
        css`
            background: ${(props) => props.theme.colors.fontLight};
            cursor: not-allowed;

            &:hover {
                background: ${(props) => props.theme.colors.fontLight};
            }
        `}
`;

const StyledCartButton = styled(StyledButton)`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden;

    > span {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: auto;
        padding: 0.75rem;
    }

    .timer {
        justify-content: start;
        height: 100%;
        min-height: 3.1rem;
        width: 6rem;
        background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 1)};
        border: 1px solid ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 1)};
        z-index: 0;
        position: relative;
        padding-left: 1rem;
        font-size: 0.875rem;
        font-weight: 700;
        font-variant-numeric: proportional-nums;

        &:after {
            content: '';
            z-index: -1;
            background-color: inherit;

            position: absolute;
            /* height & width are fixed width because Safari doesn't fully support aspect-ratio */
            height: 2rem;
            width: 2rem;
            /* height: calc(100% / 1.41421356237);
            aspect-ratio: 1; */

            transform: translateY(-50%) translateX(50%) rotate(45deg) skew(15deg, 15deg);

            border-radius: 0 4px;
            top: 50%;
            right: 0;
        }

        .clock {
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            margin-right: 0.375rem;
        }
    }

    .text {
        flex: 1;

        .cart {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 700;
            padding: 0 0.5rem;
            margin-left: 0.5rem;
            border: 1px solid #fff;
            border-radius: 0.25rem;
            color: #fff;
        }
    }
`;
