import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';
import { AppLink } from 'components/Link';
import { AddCouponLink } from './modals/CouponModal';

interface EmptyCartProps {
    cartId: string | undefined;
    brandingColor: null | string;
}

export const EmptyCart: React.FC<EmptyCartProps> = (props) => {
    const { cartId, brandingColor } = props;
    const { t } = useTranslation();
    return (
        <StyledEmptyState>
            <StyledIcon>
                <Icon name="shopping-cart-line" />
            </StyledIcon>
            <StyledTitle>{t('empty_states.cart.title')}</StyledTitle>
            <StyledText>
                <AppLink to="/">
                    <StyledInlineIcon>
                        <Icon name="arrow-left-line" />
                    </StyledInlineIcon>
                    {t('empty_states.cart.go_to_shop')}
                </AppLink>
            </StyledText>
            <div>
                <AddCouponLink cartId={cartId} brandingColor={brandingColor} emptyCart />
            </div>
        </StyledEmptyState>
    );
};

export const EmptyTickets: React.FC<{ showId?: string }> = (props) => {
    const { showId } = props;
    const { t } = useTranslation();
    return (
        <StyledEmptyState>
            <StyledIcon>
                <Icon name="ticket-line" />
            </StyledIcon>
            <StyledTitle>{t('empty_states.tickets.title')}</StyledTitle>
            <StyledText>{showId ? t('empty_states.tickets.show') : t('empty_states.tickets.edition')}</StyledText>
        </StyledEmptyState>
    );
};

const StyledEmptyState = styled.div`
    padding: 3rem 0;
    text-align: center;
    color: ${(props) => props.theme.colors.fontDark};
    height: 100%;
`;

const StyledIcon = styled.div`
    font-size: 2.75rem;
    margin-bottom: 0.75rem;
    color: ${(props) => props.theme.colors.primary};
`;

const StyledTitle = styled.h3`
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
`;

const StyledText = styled.p`
    color: ${(props) => props.theme.colors.fontMedium};
    margin-bottom: 0.5rem;

    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.primary};
        font-weight: 600;

        &:hover {
            color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
        }
    }
`;

const StyledInlineIcon = styled.span`
    display: inline-block;
    margin-right: 0.25rem;
    position: relative;
    bottom: -0.125rem;
`;
