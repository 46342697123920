import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/Icon';

export const NotFound = () => {
    const { t } = useTranslation();
    return (
        <StyledNotFound>
            <StyledInner>
                <StyledIcon>
                    <Icon name="emotion-unhappy-line" />
                </StyledIcon>
                <StyledTitle>{t('not_found.title')}</StyledTitle>
                <StyledParagraph>
                    {t('not_found.lead_1')}
                    <br />
                    {t('not_found.lead_2')}
                </StyledParagraph>
                <StyledESQLogo>
                    <a href="https://eventsquare.co" target="_blank" rel="noreferrer">
                        <img src="/esq-logo-white.svg" alt="EventSquare" />
                    </a>
                </StyledESQLogo>
            </StyledInner>
        </StyledNotFound>
    );
};

const StyledNotFound = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #068cfc; // ESQ-blue;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem;
`;

const StyledInner = styled.div`
    width: 100%;
    max-width: 32rem;
    margin-top: auto;
    margin-bottom: auto;
`;

const StyledIcon = styled.div`
    font-size: 4.5rem;
    margin-bottom: 2.5rem;

    @media (max-width: 57em) {
        margin-bottom: 1.5rem;
    }
`;

const StyledTitle = styled.h1`
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2.5rem;

    @media (max-width: 57em) {
        margin-bottom: 1.5rem;
        font-size: 2.25rem;
    }
`;

const StyledParagraph = styled.p`
    font-size: 1.5rem;
    line-height: 1.2;

    @media (max-width: 57em) {
        font-size: 1.125rem;
    }
`;

const StyledESQLogo = styled.div`
    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;

    a {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
        max-width: 12.5rem;

        @media (max-width: 57em) {
            max-width: 10rem;
        }
    }
`;
