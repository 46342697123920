import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalTitle, ModalText, ModalIcon } from 'components/modals/Modal';
import { Button } from 'components/Buttons';

import { formatTimer } from 'lib/helpers';

interface CartExpirationWarningProps {
    timeLeft: number;
    cartTimeout: number;
}

export const CartExpirationWarningModal: React.FC<CartExpirationWarningProps> = (props) => {
    const { timeLeft, cartTimeout } = props;
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalSeen, setModalSeen] = useState<boolean>(false);
    const { t } = useTranslation();

    const halfTimeCartTimeout = (cartTimeout * 60) / 2;
    const secondsLeftToSeeModal = halfTimeCartTimeout < 300 ? halfTimeCartTimeout : 300;

    useEffect(() => {
        if (timeLeft < secondsLeftToSeeModal) {
            setShowModal(true);
        }
        if (timeLeft < 1) {
            setShowModal(false);
        }
    }, [timeLeft]);

    const closeModal = () => {
        setModalSeen(true);
    };

    const isVisible = showModal && !modalSeen;

    return (
        <Modal closeModal={closeModal} center isVisible={isVisible}>
            <ModalIcon name="time-line" warning />
            <ModalTitle warning>
                {t('modal.cart_expiration_warning.title', { timer: formatTimer(timeLeft) })}
            </ModalTitle>
            <ModalText>{t('modal.cart_expiration_warning.lead', { cart_timeout: cartTimeout })}</ModalText>

            <Button buttonType="warning" fullWidth onClick={closeModal}>
                {t('modal.cart_expiration_warning.confirm')}
            </Button>
        </Modal>
    );
};
