import React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoaderProps {
    white?: boolean;
}
export const Loader: React.FC<LoaderProps> = ({ white }) => {
    return (
        <StyledLoader>
            <StyledLoaderInner white={white}>
                <div />
                <div />
                <div />
                <div />
            </StyledLoaderInner>
        </StyledLoader>
    );
};

export const LoaderWithOverlay = () => {
    return (
        <StyledLoaderWrapper>
            <Loader />
        </StyledLoaderWrapper>
    );
};

const StyledLoaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
`;

const loaderAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const StyledLoader = styled.div``;

const StyledLoaderInner = styled.div<{ white?: boolean }>`
    margin: 2rem auto;
    display: block;
    position: relative;
    width: 120px;
    height: 120px;

    div {
        display: block;
        position: absolute;
        width: 120px;
        height: 120px;
        //margin: 8px;
        border: 6px solid ${(props) => (props.white ? '#FFFFFF' : props.theme.colors.primary)};
        border-radius: 50%;
        animation-name: ${loaderAnimation};
        animation-duration: 1.2s;
        animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
        animation-iteration-count: infinite;
        border-color: ${(props) => (props.white ? '#FFFFFF' : props.theme.colors.primary)} transparent transparent
            transparent;
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }

    div:nth-child(2) {
        animation-delay: -0.3s;
    }

    div:nth-child(3) {
        animation-delay: -0.15s;
    }
`;
