import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { AppLink } from 'components/Link';
import { LangSwitch } from './LangSwitch';
import { trackLinkClick } from 'hooks/useGA';

interface ChannelNavProps {
    languages: string[];
    hasInfo: boolean;
}

export const ChannelNav: React.FC<ChannelNavProps> = (props) => {
    const { languages, hasInfo } = props;
    const { t } = useTranslation();

    return (
        <StyledChannelNav>
            <StyledNavList>
                {hasInfo && (
                    <StyledNavItem>
                        <AppLink
                            to="/info"
                            className={({ isActive }) => (isActive ? 'active' : 'none')}
                            onClick={() => trackLinkClick('info', 'channel_nav')}
                        >
                            {t('channel_nav.info')}
                        </AppLink>
                    </StyledNavItem>
                )}
                <StyledNavItem>
                    <AppLink
                        to="/support"
                        className={({ isActive }) => (isActive ? 'active' : 'none')}
                        onClick={() => trackLinkClick('help', 'channel_nav')}
                    >
                        {t('channel_nav.support')}
                    </AppLink>
                </StyledNavItem>
            </StyledNavList>

            <LangSwitch languages={languages} />
        </StyledChannelNav>
    );
};

const StyledChannelNav = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.lines};
    padding: 0 2.5rem;

    @media (max-width: 57em) {
        display: none;
    }
`;

const StyledNavList = styled.ul`
    display: flex;
    gap: 0.75rem;
    font-size: 0.875rem;
    margin-right: 0.75rem;
`;

const StyledNavItem = styled.li`
    color: ${(props) => props.theme.colors.fontMedium};
    padding: 0.75rem 0;

    a {
        text-decoration: none;
        color: inherit;
        transition: all 200ms ease;

        &.active,
        &:hover {
            font-weight: 800;
        }
    }
`;
