import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'redux/hooks';
import { fetchEdition } from 'redux/slices/storeSlice';

import { Input } from './FormElements';

export const PasswordProtected = () => {
    const { lang, editionUri, channelUri } = useParams();
    const dispatch = useAppDispatch();
    const [password, setPassword] = useState<string>('');
    const { t } = useTranslation();

    const isOnMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const entryUrl = window.location.href;

    const submitPassword = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent) => {
        e.preventDefault();
        const searchParams = new URLSearchParams(location.search);
        const previewToken = searchParams.get('preview_token') ?? undefined;
        const accessToken = searchParams.get('access_token') ?? undefined;

        if (!lang || !editionUri) return;
        dispatch(fetchEdition(lang, editionUri, entryUrl, channelUri, previewToken, accessToken, password));
    };

    return (
        <StyledPasswordForm onSubmit={submitPassword}>
            <StyledInput
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('password_protected.placeholder')}
                autoFocus={!isOnMobile}
            />
            <StyledButton type="submit">{t('password_protected.submit')}</StyledButton>
        </StyledPasswordForm>
    );
};

const StyledPasswordForm = styled.form``;

const StyledInput = styled(Input)`
    margin-bottom: 1.25rem;
`;

const StyledButton = styled.button`
    width: 100%;
    font-size: 1.25rem;
    font-family: inherit;
    border-radius: 0.25rem;
    border: 1px solid #fff;
    background-color: transparent;
    text-decoration: none;
    color: #fff;
    padding: 0.75rem 2rem;
    cursor: pointer;
    margin: 0.5rem 0 !important;
`;
