import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from 'redux/hooks';

import { Shows } from 'containers/Shows';

import { ChangeShowLink } from 'components/ChangeShowLink';

export const FlowShows = () => {
    const { event, edition } = useAppSelector((state) => {
        return { event: state.store.event, edition: state.store.edition };
    });
    const { lang, editionUri, channelUri } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!edition) return;
        if (edition.channel.flow === 'normal' || edition.channel.flow === 'disabled') {
            // Redirect to / if flow != 'reservation' or flow != 'grouped
            const url = `/${lang}/${editionUri}/${channelUri}`;
            navigate(url, { replace: true });
        }
        if (edition.channel.shows.length === 1) {
            // if only 1 show redirect to show page
            const url = `/${lang}/${editionUri}/${channelUri}/shows/${edition.channel.shows[0].id}`;
            navigate(url, { replace: true });
        }
        if (edition.channel.shows.length === 0) {
            // Redirect to / if no shows
            const url = `/${lang}/${editionUri}/${channelUri}`;
            navigate(url, { replace: true });
        }
    }, [edition]);

    if (!event || !edition) return null;

    return (
        <>
            {edition.channel.flow !== 'reservation' && <ChangeShowLink to={'/'} />}
            <FlowWrapper>
                <Shows
                    shows={edition.channel.shows}
                    title={edition.title.shows}
                    show_mode={edition.channel.show_mode}
                    lang={lang}
                    hideTitle={true}
                />
            </FlowWrapper>
        </>
    );
};

const FlowWrapper = styled.div`
    margin-bottom: 1rem;
`;
