import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Show, ShowModeType } from 'types/EditionTypes';

import { ShowsCalendar } from 'components/ShowsCalendar';
import { ShowsList } from 'components/ShowsList';

interface ShowModeProps {
    shows: Show[];
    title: string | null;
    show_mode: ShowModeType;
    lang: string | undefined;
    hideTitle: boolean;
}

export const Shows: React.FC<ShowModeProps> = (props) => {
    const { shows, title, show_mode, lang, hideTitle } = props;
    const { t } = useTranslation();

    if (shows.length === 0) return null;

    const findShowMode = () => {
        // if less than 8 shows show in list
        if (shows.length < 8) return 'listview';

        // get date of first element to check other dates
        const dayZero = shows[0].date.start ? shows[0].date.start.split(' ')[0] : null;
        // if no date, show list
        if (!dayZero) return 'listview';

        let showInList = true;
        shows.every((show) => {
            // if no date stop loop & stay in listview
            if (!show.date.start) {
                return false;
            }
            // if date is different stop loop & change to calendar
            if (show.date.start.split(' ')[0] !== dayZero) {
                showInList = false;
                return false;
            }

            return true;
        });

        return showInList ? 'listview' : 'calendar';
    };

    const checkShowMode = (mode: 'list' | 'calendar') => {
        if (mode === 'calendar') {
            const showsWithDate = shows.filter((show) => !!show.date.start);

            return showsWithDate.length === 0 ? 'listview' : mode;
        }

        return mode;
    };

    const showMode = show_mode === 'auto' ? findShowMode() : checkShowMode(show_mode);

    return (
        <>
            {!hideTitle && <StyledShowTitle>{title ?? t('shows.title')}</StyledShowTitle>}

            {showMode === 'listview' && <ShowsList shows={shows} lang={lang} />}
            {showMode === 'calendar' && <ShowsCalendar shows={shows} />}
        </>
    );
};

const StyledShowTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.5rem;
`;
