import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import { Icon } from 'components/Icon';

interface TestModeWarningProps {
    previewToken: string | undefined;
}

export const TestModeWarning: React.FC<TestModeWarningProps> = (props) => {
    const { previewToken } = props;

    if (!previewToken) return null;

    const [isWarningShown, setIsWarningShown] = useState(false);
    const { t } = useTranslation();
    const cookies = new Cookies();

    // // Remove Preview token when coming from the admin
    // if (
    //     !searchParams.get('preview_token') &&
    //     preview_token &&
    //     document.referrer &&
    //     (document.referrer.includes('admin.eventsquare.io') || document.referrer.includes('app.eventsquare.io'))
    // ) {
    //     cookies.remove('cart', { path: '/' });
    // }

    const searchParams = new URLSearchParams(window.location.search);
    const { preview_token } = cookies.get('cart') ?? { preview_token: undefined };

    const showRemovePreviewToken = !searchParams.get('preview_token') && preview_token; // &&
    // document.referrer &&
    // (document.referrer.includes('admin.eventsquare.io') || document.referrer.includes('app.eventsquare.io'));

    const removePreviewTokenAndRefresh = () => {
        // Remove cart cookie
        cookies.remove('cart', { path: '/' });
        // Redirect to current url without search parameters
        window.location.href = location.toString().replace(location.search, '');
    };

    return (
        <StyledTestMode checked={isWarningShown}>
            <StyledInner>
                {!isWarningShown && (
                    <>
                        <StyledIcon>
                            <Icon name="rocket-line" />
                        </StyledIcon>
                        <StyledTitle>{t('testmode.title')}</StyledTitle>
                        <StyledParagraph>{t('testmode.lead')}</StyledParagraph>
                        <StyledButtonWrapper>
                            <button onClick={() => setIsWarningShown(true)}>{t('testmode.confirm')}</button>
                            {showRemovePreviewToken && (
                                <button className="ghost" onClick={removePreviewTokenAndRefresh}>
                                    {t('testmode.stop_testmode')}
                                </button>
                            )}
                        </StyledButtonWrapper>
                    </>
                )}
                {isWarningShown && (
                    <StyledDemoMessage>
                        <Icon name="rocket-line" />
                        {t('testmode.message')}
                    </StyledDemoMessage>
                )}
            </StyledInner>
        </StyledTestMode>
    );
};

const StyledTestMode = styled.div<{ checked: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background-color: #ffb400;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem;
    transition: all 300ms ease;

    ${(props) =>
        props.checked &&
        css`
            bottom: unset;
            padding: 1rem 2rem;
        `}
`;

const StyledInner = styled.div`
    width: 100%;
    max-width: 32rem;
    margin-top: auto;
    margin-bottom: auto;
`;

const StyledIcon = styled.div`
    font-size: 4.5rem;
    margin-bottom: 2.5rem;

    @media (max-width: 57em) {
        margin-bottom: 1.5rem;
    }
`;

const StyledTitle = styled.h1`
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2.5rem;

    @media (max-width: 57em) {
        margin-bottom: 1.5rem;
        font-size: 2.25rem;
    }
`;

const StyledParagraph = styled.p`
    font-size: 1.5rem;
    line-height: 1.2;

    @media (max-width: 57em) {
        font-size: 1.125rem;
    }
`;

const StyledButtonWrapper = styled.div`
    margin-top: 2.5rem;

    button {
        display: inline-block;
        font-size: 1.25rem;
        font-family: inherit;
        border-radius: 0.25rem;
        border: 1px solid #fff;
        background-color: transparent;
        text-decoration: none;
        color: #fff;
        padding: 1rem 2rem;
        cursor: pointer;
        opacity: 1;
        margin: 0.5rem;

        &:hover {
            color: #fff;
            opacity: 0.8;
        }

        &.ghost {
            border: none;
            margin-top: 1rem;
        }
    }

    @media (max-width: 57em) {
        margin-top: 1.5rem;

        button {
            font-size: 1.125rem;
        }
    }
`;

const StyledDemoMessage = styled.p`
    font-size: 1rem;
    font-weight: 700;

    svg {
        display: inline-block;
        position: relative;
        bottom: -0.125rem;
        margin-right: 0.25rem;
    }
`;
