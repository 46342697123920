import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { setCart } from 'redux/slices/storeSlice';

import { formatCurrency } from 'lib/helpers';

import { ShowDetail, EditTicketType } from 'types/EditionTypes';

import { Icon } from 'components/Icon';
import { SeatmapErrorModal, SeatmapErrorType } from 'components/modals/SeatmapErrorModal';
import { SelectPlaceTypeModal } from './modals/SelectPlaceTypeModal';
import { EditTypeModal } from 'components/modals/EditTypeModal';
import { EditButton } from 'components/EditButton';

declare const seatmap: any;
interface SeatmapProps {
    seatmapData: {
        id: string;
        json: string;
        name: string;
        seatmapid: string;
        svg: null;
    };
    show: ShowDetail | undefined;
}

export const Seatmap: React.FC<SeatmapProps> = (props) => {
    const { seatmapData, show } = props;
    const { event, edition, cartId, cart } = useAppSelector((state) => {
        return {
            event: state.store.event,
            edition: state.store.edition,
            cartId: state.store.cartId,
            cart: state.store.cart,
        };
    });

    const [map, setMap] = useState<any>(null);
    const [clickedPlace, setClickedPlace] = useState<any | null>(null);
    const [activeFilter, setActiveFilter] = useState<string>('');
    const [isTypesListOpen, setIsTypesListOpen] = useState<boolean>(false);
    const [seatmapTypes, setSeatmapTypes] = useState<any>([]);
    const [seatmapTypesExtraData, setSeatmapTypesExtraData] = useState<any>([]);
    const [showSelectTypeModal, setShowSelectTypeModal] = useState<boolean>(false);
    const [seatmapError, setSeatmapError] = useState<SeatmapErrorType>(null);
    const [activeAreaType, setActiveAreaType] = useState<EditTicketType | undefined>(undefined);

    const { lang, editionUri, channelUri, showId } = useParams();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    if (!event || !seatmapData || !channelUri || !cartId || !cart || !show) return null;

    useEffect(() => {
        loadShowDetails();
        getPlacesFromCart();
    }, []);

    useEffect(() => {
        if (!map) return;
        map.load((data: any) => {
            setSeatmapTypesExtraData(data.types);
            selectPlacesFromCart();
        });
        return () => {
            map.unmount();
        };
    }, [map]);

    useEffect(() => {
        if (!clickedPlace) return;
        // prevent other clicks on other places
        document.body.style.pointerEvents = 'none';

        const place = clickedPlace.place;
        const area = clickedPlace.area;
        const action = clickedPlace.action;

        if (action === 'select') {
            if (area) {
                const clickedType = seatmapTypes.find((type: any) => type.id === clickedPlace.types[0].id);
                setActiveAreaType({ ...clickedType, showId: show.id });
                document.body.style.pointerEvents = 'auto';
                return;
            }

            if (clickedPlace.types.length === 0) {
                document.body.style.pointerEvents = 'auto';
                return;
            }

            if (clickedPlace.types.length === 1) {
                modifyCart(place, clickedPlace.types[0].id, action);
            } else {
                document.body.style.pointerEvents = 'auto';
                setShowSelectTypeModal(true);
            }
        } else {
            const typeId = getTypeFromCartByPlace(clickedPlace.place);
            if (typeId) {
                modifyCart(place, typeId, action);
            } else {
                document.body.style.pointerEvents = 'auto';
            }
        }
    }, [clickedPlace]);

    useEffect(() => {
        if (!map) return;
        map.deselectPlaces();
        if (!activeFilter) {
            selectPlacesFromCart();
            map.setTypes([]);
        } else {
            selectPlacesFromCart();
            map.setTypes([activeFilter]);
        }
    }, [activeFilter]);

    const getTypeFromCartByPlace = (selectedPlace: any) => {
        const showId = show.id;
        const seatmapId = seatmapData.id;
        const cartItems = cart.items.types.filter((type) => {
            return type.show?.id === showId && type.seatmap?.id === seatmapId;
        });

        const cartItem = cartItems
            .filter((item) => item.seatmap && !!item.seatmap.places.length)
            .find((item) => item.seatmap?.places.find((place) => place.object === selectedPlace));

        return cartItem ? cartItem.id : null;
    };

    const loadSeatmap = () => {
        const loadedMap = new seatmap.Map({
            el: 'seatmap-map',
            apikey: 'pCdjRIc7jxGPCUvU7oG0NHLSTQQI5WUW',
            endpoint: process.env.REACT_APP_API_ENDPOINT ?? 'https://api.eventsquare.io/1.0',
            autoZoom: false,
            map: seatmapData.seatmapid,
            channel: channelUri,
            select: (e: any) => {
                setClickedPlace({ ...e, action: 'select' });
            },
            deselect: (e: any) => {
                setClickedPlace({ ...e, action: 'deselect' });
            },
            error: function (err: any) {
                if (err === 'place_disabled' || err === 'place_forbidden') {
                    setSeatmapError(err);
                }
                console.warn(err);
            },
        });
        setMap(loadedMap);
    };

    const loadShowDetails = async () => {
        try {
            const subdomain = event.uri;
            const params = {
                cart: cartId,
                language: lang,
            };
            const response = await axios.get(
                `/api/store/${subdomain}/${editionUri}/${channelUri}/${showId}/${seatmapData.id}`,
                {
                    params,
                    timeout: 30000,
                },
            );
            const { types } = response.data.seatmap;
            setSeatmapTypes(types);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(error.response?.data);
                // TODO : setError or redirect?s
            }
        } finally {
            loadSeatmap();
        }
    };

    const selectPlacesFromCart = (filteredType: string | undefined = undefined) => {
        const placesInCart = getPlacesFromCart(filteredType);
        map.selectPlaces([...placesInCart]);
    };

    const getTypeQuantityFromShowInCart = (typeId: string) => {
        const showTypeInCart = cart.items.types.filter((type) => {
            return type.id === typeId && type.show?.id === show.id;
        });
        return !!showTypeInCart.length ? showTypeInCart[0].quantity : 0;
    };

    const getPlacesFromCart = (filteredType: string | undefined = undefined) => {
        const selectedPlacesFromCart: string[] = [];
        let cartTypes = cart.items.types.filter(
            (type) => type.show?.id === show.id && type.seatmap?.id === seatmapData.id,
        );
        if (filteredType) {
            cartTypes = cartTypes.filter((type) => type.id === filteredType);
        }
        cartTypes.forEach((type) => type.seatmap?.places.forEach((place) => selectedPlacesFromCart.push(place.object)));
        return selectedPlacesFromCart;
    };

    const modifyCart = async (place: string, typeId: string, action: 'select' | 'deselect') => {
        try {
            const data = {
                quantity: action === 'select' ? 1 : -1,
                show: show.id,
                seatmap: seatmapData.id,
                places: [place],
            };
            const response = await axios.put(`/api/cart/${cartId}/types/${typeId}`, data, { timeout: 30000 });
            dispatch(setCart(response.data.cart));
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                // To-do: show all errors in modal
                console.error(error.response?.data);
                if (error.response?.data.error && error.response?.data.error === 'max_quantity') {
                    setSeatmapError('max_quantity');
                }
                switch (action) {
                    case 'select':
                        map.deselectPlaces([place]);
                        break;
                    case 'deselect':
                        map.selectPlaces([place]);
                        break;
                }
            }
        } finally {
            document.body.style.pointerEvents = 'auto';
            setClickedPlace(null);
        }
    };

    const selectActiveFilter = (type: string) => {
        setActiveFilter(type);
        setIsTypesListOpen(false);
    };

    const activeFilterName = (filter: string): string => {
        if (!filter) return t('seatmap.show_all_types');
        return seatmapTypes.find((type: any) => type.id === filter).name;
    };

    const editAreaType = (type: EditTicketType) => {
        setActiveAreaType({ ...type, showId: show.id });
    };

    const closeModal = () => {
        setActiveAreaType(undefined);
    };

    return (
        <>
            <StyledSeatmapWrapper>
                {seatmapTypes.length > 1 && (
                    <StyledSeatmapSidebar>
                        <StyledMobileActiveFilter>
                            <h6>{t('seatmap.select_label')}</h6>
                            <StyledListToggle onClick={() => setIsTypesListOpen(!isTypesListOpen)}>
                                <div>{activeFilterName(activeFilter)}</div>
                                <StyledArrow open={isTypesListOpen}>
                                    <Icon name="arrow-down-s-line" />
                                </StyledArrow>
                            </StyledListToggle>
                        </StyledMobileActiveFilter>
                        <StyledTypesList open={isTypesListOpen}>
                            <StyledTypeListItem
                                onClick={() => selectActiveFilter('')}
                                active={activeFilter === ''}
                                brandingColor="transparent"
                            >
                                <div className="type">
                                    <h5>{t('seatmap.show_all_types')}</h5>
                                </div>
                            </StyledTypeListItem>
                            {seatmapTypes.map((type: any) => {
                                const quantity = getTypeQuantityFromShowInCart(type.id);
                                const extraData = seatmapTypesExtraData.find((extra: any) => extra.id === type.id);
                                const typeType = extraData?.areas?.length > 0 ? 'area' : 'place';
                                return (
                                    <StyledTypeListItem
                                        key={type.id}
                                        onClick={() => selectActiveFilter(type.id)}
                                        brandingColor={type.branding.color}
                                        active={activeFilter === type.id}
                                    >
                                        <div className="type">
                                            <div className="type-info">
                                                <h5>{type.name}</h5>
                                                <h6>
                                                    {!!type.price
                                                        ? formatCurrency(type.price, lang, event.currency)
                                                        : t('tickets.free')}
                                                    {!!type.fee && (
                                                        <span className="fee">
                                                            {t('tickets.fee', {
                                                                fee: formatCurrency(type.fee, lang, event.currency),
                                                            })}
                                                        </span>
                                                    )}
                                                    {!!type.price && edition?.channel.hide_vat && (
                                                        <span className="vat_info">{t('tickets.VAT_excl')}</span>
                                                    )}
                                                </h6>
                                                {type.status === 'soldout' && (
                                                    <p className="type-status">{t('seatmap.type_status_soldout')}</p>
                                                )}
                                            </div>
                                            {typeType === 'area' && type.status !== 'soldout' && (
                                                <EditButton
                                                    buttonType={quantity ? 'edit' : 'ghost'}
                                                    onClick={() => editAreaType(type)}
                                                >
                                                    {quantity ? quantity : <Icon name="add-fill" />}
                                                </EditButton>
                                            )}
                                            {typeType === 'place' && !!quantity && (
                                                <div className="type-quantity">{quantity}</div>
                                            )}
                                        </div>
                                    </StyledTypeListItem>
                                );
                            })}
                        </StyledTypesList>
                    </StyledSeatmapSidebar>
                )}
                <StyledSeatmapBox fullWidth={seatmapTypes.length <= 1}>
                    {cart.items.types.length === 0 && (
                        <StyledSeatmapInfo>
                            <p>{t('seatmap.info')}</p>
                        </StyledSeatmapInfo>
                    )}
                    <StyledSeatmap id="seatmap-map"></StyledSeatmap>
                </StyledSeatmapBox>
            </StyledSeatmapWrapper>
            <SeatmapErrorModal
                seatmapError={seatmapError}
                closeModal={() => {
                    setSeatmapError(null);
                }}
            />
            <SelectPlaceTypeModal
                clickedPlace={clickedPlace}
                showModal={showSelectTypeModal}
                deselectPlace={(id: string) => map.deselectPlaces([id])}
                modifyCart={modifyCart}
                closeModal={() => setShowSelectTypeModal(false)}
            />
            <EditTypeModal
                type={activeAreaType}
                closeModal={closeModal}
                cart={cart}
                showDate={show.date}
                showName={show.name}
                typeMode={'auto'}
                currency={event.currency}
                edition={edition}
            />
        </>
    );
};

const StyledSeatmapWrapper = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 57em) {
        flex-direction: column;
    }
`;

const StyledSeatmapSidebar = styled.div`
    width: 17rem;
    position: relative;
    margin-right: 1rem;

    @media (max-width: 57em) {
        width: 100%;
        margin-right: 0;
    }
`;

const StyledMobileActiveFilter = styled.div`
    display: none;

    @media (max-width: 57em) {
        display: block;

        h6 {
            text-transform: uppercase;
            font-size: 0.75rem;
            font-weight: 700;
            letter-spacing: 0.5px;
            margin-bottom: 0.75rem;
        }
    }
`;

const StyledListToggle = styled.button`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.colors.lines};
    border-radius: 0.25rem;
    padding: 0.625rem 1rem;
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 1rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.fontMedium};
    margin-bottom: 1.5rem;
`;

const StyledArrow = styled.div<{ open: boolean }>`
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
    transition: transform 200ms ease;

    ${(props) =>
        props.open &&
        css`
            transform: rotate(180deg);
        `}
`;

const StyledTypesList = styled.ul<{ open: boolean }>`
    display: block;
    position: relative;
    width: 100%;
    cursor: pointer;

    @media (max-width: 57em) {
        display: none;
        overflow: hidden;
        position: absolute;
        width: 100%;
        top: 3.5rem;
        background-color: #fff;
        border: 1px solid ${(props) => props.theme.colors.lines};
        border-radius: 0.25rem;
        z-index: 100;
        box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.fontMedium)};

        ${(props) =>
            props.open &&
            css`
                display: block;
            `}
    }
`;

const StyledTypeListItem = styled.li<{ brandingColor: string; active: boolean }>`
    padding: 0.5rem 0.125rem;
    position: relative;

    &:before {
        content: '';
        height: calc(100% - 2rem);
        width: 0.25rem;
        border-radius: 0.125rem;
        position: absolute;
        top: 1rem;
        left: 0.5rem;
        background-color: transparent;
        transition: all 200ms ease;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.colors.lines};
    }

    &:hover {
        &:before {
            left: 0.625rem;
        }

        h5 {
            font-weight: 800;
        }
    }

    .type {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.5rem 0.5rem 1.25rem;

        .type-quantity {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            border-radius: 0.25rem;
            font-weight: 600;
            padding: 0.5rem 0.75rem;
            box-shadow: ${(props) => props.theme.helpers.boxShadow(props.theme.colors.primary)};
            color: #fff;
            border: none;
            background-color: ${(props) => props.theme.colors.primary};
            min-width: 2.125rem;
        }
    }

    h5 {
        font-size: 1.125rem;
        font-weight: 600;
    }

    h6 {
        font-size: 1rem;
        font-weight: 400;
        margin-top: 0.25rem;

        span.fee,
        span.vat_info {
            display: inline-block;
            margin-left: 0.25rem;
            color: ${(props) => props.theme.colors.fontMedium};
            font-size: 0.75rem;
        }
    }

    p.type-status {
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.fontMedium};
        font-weight: 700;
        font-size: 0.75rem;
        margin-top: 0.25rem;
    }

    ${(props) =>
        props.brandingColor &&
        css`
            &:before {
                background-color: ${props.brandingColor};
            }
        `}

    ${(props) =>
        props.active &&
        css`
            .type {
                background-color: ${(props) => props.theme.colors.light};
                border-radius: 0.25rem;

                h5 {
                    font-weight: 800;
                }
            }
        `}

    @media (max-width: 57em) {
        padding: 1rem 1rem 1rem 1.5rem;

        &:before {
            left: 0.5rem;
        }
    }
`;

// const StyledCheck = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 1.25rem;
//     color: ${(props) => props.theme.colors.fontMedium};
//     margin-left: auto;
//     margin-right: 0.5rem;
// `;

const StyledSeatmapBox = styled.div<{ fullWidth: boolean }>`
    width: calc(100% - 18rem);

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
        `}

    @media (max-width: 57em) {
        width: 100%;
    }
`;

const StyledSeatmapInfo = styled.div`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    padding: 0.75rem;
    background-color: ${(props) => props.theme.colors.light};
    border-radius: 0.25rem;
    text-align: center;
`;

const StyledSeatmap = styled.div`
    height: 400px;
    width: 100%;
`;
