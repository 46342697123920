import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'redux/hooks';

import { Button } from 'components/Buttons';
import { Input, Label, TextArea, FormDiv } from 'components/FormElements';
import { OrganisationInfo } from 'components/OrganisationInfo';
import { Icon } from 'components/Icon';
import { LoaderWithOverlay } from 'components/LoadingSpinner';
import { AppLink } from 'components/Link';

export const Support = () => {
    const { event, edition } = useAppSelector((state) => {
        return {
            event: state.store.event,
            edition: state.store.edition,
        };
    });

    const { lang } = useParams();
    const { t } = useTranslation();

    if (!edition || !event || !lang) return null;

    const errorsRef = useRef<any>();

    useEffect(() => {
        if (errorsRef?.current) {
            errorsRef.current.scrollIntoView();
        }
    }, [errorsRef]);

    const intitialFormValues = {
        fname: '',
        lname: '',
        email: '',
        phone: '',
        message: '',
    };

    interface formValuesType {
        fname: string;
        lname: string;
        email: string;
        phone: string;
        message: string;
    }

    type caseType = 'tickets' | 'question' | 'technical';

    const [page, setPage] = useState<1 | 2 | 3>(1);
    const [supportCase, setSupportCase] = useState<caseType | undefined>(undefined);
    const [formValues, setFormValues] = useState<formValuesType>(intitialFormValues);
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const setCaseHandler = (type: caseType) => {
        setSupportCase(type);
        setPage(2);
    };

    const resetSupport = () => {
        setSupportCase(undefined);
        setPage(1);
        setFormValues(intitialFormValues);
        setFormErrors([]);
    };

    const changeHandler = (e: { target: HTMLInputElement }) => {
        const value = e.target.value as string;
        const name = e.target.name as 'fname' | 'lname' | 'email' | 'phone' | 'message';
        const values = { ...formValues };
        values[name] = value;
        setFormValues(values);
    };
    interface supportData {
        edition: string;
        firstname: string;
        lastname: string;
        email: string;
        telephone: string;
        language: string;
        message?: string;
    }

    const sendSupportForm = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setFormErrors([]);
        setIsLoading(true);

        const errorsArr: string[] = [];
        const keys = Object.keys(formValues);
        keys.forEach((key) => {
            if (key === 'phone') return;
            if (key === 'message' && supportCase === 'tickets') return;
            if ((formValues as any)[key].trim().length == 0) {
                errorsArr.push(`${key}_required`);
            }
        });

        if (errorsArr.length > 0) {
            setFormErrors(errorsArr);
            setIsLoading(false);
            return;
        }

        const apiUrl = `/api/support/${supportCase}`;
        const { fname, lname, email, phone } = formValues;
        const data: supportData = {
            edition: edition.id,
            firstname: fname,
            lastname: lname,
            email: email,
            telephone: phone,
            language: lang,
        };

        if (supportCase !== 'tickets') {
            data.message = formValues.message;
        }

        try {
            const response = await axios.post(apiUrl, data, { timeout: 30000 });
            if (response) setPage(3);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.error(error.response?.data);
                const { response } = error;
                if (response?.data.error === 'validation_failed') {
                    const keys = Object.keys(response?.data.validation);
                    keys.forEach((key) => {
                        errorsArr.push(`${key}_${response?.data.validation[key]}`);
                    });
                }
                setFormErrors(errorsArr);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <StyledSupport>
            {page === 1 && (
                <>
                    <StyledTitle>{t('support.page_1.title')}</StyledTitle>
                    <StyledButtonGrid>
                        <StyledSupportButton
                            fullWidth
                            buttonType="withGrayShadow"
                            onClick={() => setCaseHandler('tickets')}
                        >
                            {t('support.page_1.tickets')}
                        </StyledSupportButton>
                        <StyledSupportButton
                            fullWidth
                            buttonType="withGrayShadow"
                            onClick={() => setCaseHandler('question')}
                        >
                            {t('support.page_1.question', { event: `${event.name} ${edition.name ?? ''}` })}
                        </StyledSupportButton>
                        <StyledSupportButton
                            fullWidth
                            buttonType="withGrayShadow"
                            onClick={() => setCaseHandler('technical')}
                        >
                            {t('support.page_1.technical')}
                        </StyledSupportButton>
                    </StyledButtonGrid>
                </>
            )}
            {page === 2 && supportCase && (
                <>
                    <StyledBackButton buttonType="text" onClick={resetSupport}>
                        <Icon name="arrow-left-line" />
                        {t('support.page_2.go_back')}
                    </StyledBackButton>
                    <StyledTitle>{t(`support.page_2.form.title.${supportCase}`)}</StyledTitle>
                    <StyledSupportForm onSubmit={sendSupportForm}>
                        {!!formErrors.length && (
                            <StyledErrors onClick={() => setFormErrors([])} ref={errorsRef}>
                                {formErrors.map((error, i) => (
                                    <p key={`${i}_${error}`}>{t(`support.page_2.error.${error}`)}</p>
                                ))}
                            </StyledErrors>
                        )}
                        <FormDiv>
                            <Label htmlFor="firstname">{t('support.page_2.form.first_name')}</Label>
                            <Input
                                name="fname"
                                id="firstname"
                                autocomplete="given-name"
                                value={formValues.fname}
                                onChange={changeHandler}
                            />
                        </FormDiv>
                        <FormDiv>
                            <Label htmlFor="lastname">{t('support.page_2.form.last_name')}</Label>
                            <Input
                                name="lname"
                                id="lastname"
                                autocomplete="family-name"
                                value={formValues.lname}
                                onChange={changeHandler}
                            />
                        </FormDiv>
                        <FormDiv>
                            <Label htmlFor="email">{t('support.page_2.form.email')}</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                autocomplete="email"
                                value={formValues.email}
                                onChange={changeHandler}
                            />
                        </FormDiv>
                        <FormDiv>
                            <Label htmlFor="telephone" optional>
                                {t('support.page_2.form.phone')}
                            </Label>
                            <Input
                                type="tel"
                                name="phone"
                                id="telephone"
                                autocomplete="tel"
                                value={formValues.phone}
                                onChange={changeHandler}
                            />
                        </FormDiv>
                        {supportCase !== 'tickets' && (
                            <FormDiv>
                                <Label htmlFor="message">{t('support.page_2.form.message')}</Label>
                                <TextArea
                                    name="message"
                                    id="message"
                                    value={formValues.message}
                                    onChange={changeHandler}
                                />
                            </FormDiv>
                        )}
                        <FormDiv>
                            <Button fullWidth type="submit">
                                {t('support.page_2.form.send')}
                            </Button>
                        </FormDiv>
                        {isLoading && <LoaderWithOverlay />}
                    </StyledSupportForm>
                </>
            )}
            {page === 3 && (
                <>
                    <StyledFinishedState>
                        <StyledIcon>
                            <Icon name="mail-send-line" />
                        </StyledIcon>
                        <StyledFinishedTitle>{t('support.page_3.title')}</StyledFinishedTitle>
                        <StyledText>{t('support.page_3.lead')}</StyledText>
                        <StyledText>
                            <AppLink to="/">
                                <StyledInlineIcon>
                                    <Icon name="arrow-left-line" />
                                </StyledInlineIcon>
                                {t('support.page_3.go_to_shop')}
                            </AppLink>
                        </StyledText>
                    </StyledFinishedState>
                </>
            )}
            {page !== 3 && <OrganisationInfo organisation={event.organisation} />}
        </StyledSupport>
    );
};

const StyledSupport = styled.div``;

const StyledTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 1rem;
`;

const StyledButtonGrid = styled.div`
    display: grid;
    gap: 1.5rem;
    margin-bottom: 2.5rem;

    button {
        text-align: left;
        padding: 1rem 1.5rem;
    }
`;

const StyledSupportButton = styled(Button)`
    background-color: #fff;
    color: ${(props) => props.theme.colors.primary};
    transition: all 200ms ease;

    &:hover {
        background-color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -1)};
        color: #fff;
    }
`;

const StyledSupportForm = styled.form`
    position: relative;
    display: block;
    margin-bottom: 1.5rem;
`;

const StyledErrors = styled.div`
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.helpers.getWarningColor(props.theme.colors.primary)};
    color: #fff;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1.5rem;
`;

const StyledBackButton = styled(Button)`
    padding: 0;
    font-weight: 700;
    margin-bottom: 1.5rem;

    svg {
        display: inline-block;
        position: relative;
        margin-right: 0.25rem;
        bottom: -0.125rem;
    }
`;

const StyledFinishedState = styled.div`
    padding: 3rem 0;
    text-align: center;
    color: ${(props) => props.theme.colors.fontDark};
    height: 100%;
`;

const StyledIcon = styled.div`
    font-size: 2.75rem;
    margin-bottom: 0.75rem;
    color: ${(props) => props.theme.colors.primary};
`;

const StyledFinishedTitle = styled.h3`
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
`;

const StyledText = styled.p`
    color: ${(props) => props.theme.colors.fontMedium};
    margin-bottom: 1.5rem;

    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.primary};
        font-weight: 600;

        &:hover {
            color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, 2)};
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }
`;

const StyledInlineIcon = styled.span`
    display: inline-block;
    margin-right: 0.25rem;
    position: relative;
    bottom: -0.125rem;
`;
