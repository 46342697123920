import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, ModalTitle, ModalIcon } from 'components/modals/Modal';
import { Button } from 'components/Buttons';

interface SelectPlaceTypeProps {
    clickedPlace: any | undefined;
    showModal: boolean;
    closeModal: () => void;
    deselectPlace: (placeId: string) => void;
    modifyCart: (place: string, typeId: string, action: 'select' | 'deselect') => void;
}

export const SelectPlaceTypeModal: React.FC<SelectPlaceTypeProps> = (props) => {
    const { clickedPlace, showModal, closeModal, deselectPlace, modifyCart } = props;

    const [selectedType, setSelectedType] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!showModal) return;
        setSelectedType(clickedPlace.types[0].id);
    }, [showModal]);

    const { t } = useTranslation();

    const selectType = () => {
        if (!selectedType) return;
        document.body.style.pointerEvents = 'none';
        closeModal();
        modifyCart(clickedPlace.place, selectedType, clickedPlace.action);
    };

    const changeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedType(e.target.value);
    };

    const onCancel = () => {
        deselectPlace(clickedPlace.place);
        closeModal();
    };

    if (!showModal) return null;

    return (
        <Modal closeModal={onCancel} center isVisible={showModal}>
            <ModalIcon name="ticket-line" />
            <ModalTitle>{t('modal.select_seatmap_type.title')}</ModalTitle>
            <StyledSeatTypeSelect>
                <select value={selectedType} onChange={changeType}>
                    {clickedPlace.types.map((type: any) => (
                        <option key={type.id} value={type.id}>
                            {type.name}
                        </option>
                    ))}
                </select>
            </StyledSeatTypeSelect>
            <Button onClick={selectType} fullWidth>
                {t('modal.select_seatmap_type.button')}
            </Button>
        </Modal>
    );
};

const StyledSeatTypeSelect = styled.label`
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    margin-bottom: 1.5rem;

    select,
    select:focus {
        width: 100%;
        padding: 0.75rem 1rem 0.75rem 0.75rem;
        border: 1px solid ${(props) => props.theme.colors.lines};
        border-radius: 0.25rem;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: ${(props) => props.theme.fontFamily};
        font-size: 1rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors.fontDark};
        cursor: pointer;
        background-color: transparent;
    }

    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        border-top: 6px solid ${(props) => props.theme.colors.fontMedium};
        position: absolute;
        top: 19px;
        right: 16px;
        pointer-events: none;
    }
`;
