import { useEffect, useState } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { EventSquareEdition, TikTokIntegrationCredentials } from 'types/EditionTypes';
import { EventSquareEvent } from 'types/EventTypes';
import { useLocation } from 'react-router-dom';

export const useTikTokPixel = (event: EventSquareEvent | undefined, edition: EventSquareEdition | undefined) => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        document.addEventListener('Track', handleTrack);
        return () => {
            document.removeEventListener('Track', handleTrack);
        };
    }, [event, edition, initialized]);

    useEffect(() => {
        const ttIntegration = edition?.integrations.find((intergration) => intergration.type === 'tiktok_pixel');
        if (!edition || !ttIntegration) {
            setInitialized(false);
        } else {
            const { pixel_id } = ttIntegration.credentials as TikTokIntegrationCredentials;
            TiktokPixel.init(pixel_id);
            setInitialized(true);
        }
    }, [event, edition]);

    useEffect(() => {
        if (!initialized) return;
        TiktokPixel.pageView();
    }, [location, initialized]);

    const handleTrack = (data: any) => {
        if (!initialized) return;
        switch (data.detail.type) {
            case 'AddToCart':
                const category = event?.name + (edition?.name ? ` - ${edition?.name}` : '');
                TiktokPixel.track('AddToCart', {
                    content_type: 'product',
                    content_id: data.detail.type_id,
                    content_category: category,
                    content_name: data.detail.name,
                    currency: event?.currency,
                    value: data.detail.price,
                });
                break;
            default:
                break;
        }
    };
};
