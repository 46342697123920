import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import { Modal, ModalTitle, ModalText, ModalIcon } from 'components/modals/Modal';
import { Button } from 'components/Buttons';

interface ExpiredCartModalProps {
    showModal: boolean;
    closeModal: () => void;
    cartTimeout: number;
}

export const ExpiredCartModal: React.FC<ExpiredCartModalProps> = (props) => {
    const { showModal, closeModal, cartTimeout } = props;

    const cookies = new Cookies();
    const { t } = useTranslation();

    const handleExpiredCart = () => {
        cookies.remove('cart', { path: '/' });
        location.reload();
        closeModal();
    };

    return (
        <Modal closeModal={handleExpiredCart} center isVisible={showModal}>
            <ModalIcon name="emotion-sad-line" warning />
            <ModalTitle warning>{t('modal.expired_cart.title')}</ModalTitle>
            <ModalText>{t('modal.expired_cart.lead', { cart_timeout: cartTimeout })}</ModalText>
            <Button buttonType="warning" onClick={handleExpiredCart} fullWidth>
                {t(`modal.expired_cart.button`)}
            </Button>
        </Modal>
    );
};
