import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

interface MarkdownProps {
    children: string | null;
}

export const Markdown: React.FC<MarkdownProps> = (props) => {
    const { children } = props;

    if (!children) return null;

    return (
        <StyledMarkdown>
            <ReactMarkdown>{children}</ReactMarkdown>
        </StyledMarkdown>
    );
};

const StyledMarkdown = styled.div`
    color: ${(props) => props.theme.colors.fontMedium};
    margin-bottom: 1.5rem;
    line-height: 1.2;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 700;
        margin-bottom: 1rem;
        line-height: 1;
    }

    h1 {
        font-size: 1.802rem;
    }

    h2 {
        font-size: 1.602rem;
    }

    h3 {
        font-size: 1.424rem;
    }

    h4 {
        font-size: 1.266rem;
    }

    h5 {
        font-size: 1.125rem;
    }

    p {
        margin-bottom: 1rem;
    }

    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        /* font-style: italic; */
        font-variation-settings: 'ital' 125;
    }

    a {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;

        &:hover {
            color: ${(props) => props.theme.helpers.shades(props.theme.colors.primary, -1)};
        }
    }

    ul,
    ol {
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
        padding-left: 1rem;
        list-style-position: inside;

        li {
            padding-left: 0.5rem;
            margin-bottom: 1rem;
        }
    }

    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
    }
`;
