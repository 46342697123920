import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'redux/hooks';
import { setCartId } from 'redux/slices/storeSlice';

import { Modal, ModalTitle, ModalText, ModalIcon } from 'components/modals/Modal';
import { Button } from 'components/Buttons';
import { EventSquareEvent } from 'types/EventTypes';

interface ChangeEditionProps {
    event: EventSquareEvent;
    newEditionUri: string | undefined;
    unsetNewEditionUri: () => void | undefined;
    search?: string | undefined | null;
    onEditionsList?: boolean;
}

export const ChangeEditionModal: React.FC<ChangeEditionProps> = (props) => {
    const { event, newEditionUri, unsetNewEditionUri, search, onEditionsList } = props;
    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { lang } = useParams();
    const { t } = useTranslation();

    const cartCookie = cookies.get('cart');

    const getEditionName = (uri: string | undefined): string => {
        if (!uri) return '';
        const editionObj = event.editions.find((edition) => edition.uri === uri);
        return editionObj?.name ? editionObj.name : t('modal.change_edition.first_edition');
    };

    const toOldEdition = () => {
        if (onEditionsList) {
            const { edition, channel } = cartCookie;
            navigate(`/${lang}/${edition}/${channel}${search}`);
        } else {
            unsetNewEditionUri();
        }
    };

    const toNewEdition = () => {
        dispatch(setCartId(undefined));
        cookies.remove('cart', { path: '/' });
        navigate(`/${lang}/${newEditionUri}`);
        unsetNewEditionUri();
    };

    if (!newEditionUri && !unsetNewEditionUri) {
        return null;
    }

    return (
        <Modal closeModal={toOldEdition} center isVisible={!!newEditionUri}>
            <ModalIcon name="error-warning-line" warning />
            <ModalTitle warning>{t('modal.change_edition.title')}</ModalTitle>
            <ModalText>{t('modal.change_edition.lead')}</ModalText>
            <ButtonGrid>
                <Button fullWidth onClick={toOldEdition}>
                    {onEditionsList && cartCookie?.edition
                        ? t('modal.change_edition.go_back_to', { edition: getEditionName(cartCookie.edition) })
                        : t('modal.change_edition.stay_here')}
                </Button>
                <Button fullWidth onClick={toNewEdition} buttonType="text">
                    {t('modal.change_edition.go_to', { edition: getEditionName(newEditionUri) })}
                </Button>
            </ButtonGrid>
        </Modal>
    );
};

const ButtonGrid = styled.div`
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
`;
