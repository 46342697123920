import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalTitle, ModalText, ModalIcon } from 'components/modals/Modal';
import { Button } from 'components/Buttons';

interface SeatmapErrorModalProps {
    seatmapError: SeatmapErrorType;
    closeModal: () => void;
}

export type SeatmapErrorType = 'place_disabled' | 'place_forbidden' | 'max_quantity' | null;

export const SeatmapErrorModal: React.FC<SeatmapErrorModalProps> = (props) => {
    const { seatmapError, closeModal } = props;

    const { t } = useTranslation();

    return (
        <Modal closeModal={closeModal} center isVisible={!!seatmapError}>
            <ModalIcon name="emotion-sad-line" warning />
            <ModalTitle warning>{t(`modal.seatmap_error.${seatmapError}.title`)}</ModalTitle>
            <ModalText>{t(`modal.seatmap_error.${seatmapError}.lead`)}</ModalText>
            <Button buttonType="warning" onClick={closeModal} fullWidth>
                {t(`modal.seatmap_error.${seatmapError}.button`)}
            </Button>
        </Modal>
    );
};
