import { createGlobalStyle, DefaultTheme } from 'styled-components';
import { rgba, transparentize, darken, lighten, setHue } from 'polished';

// Define theme typings
declare module 'styled-components' {
    export interface DefaultTheme {
        fontFamily: string;
        colors: {
            primary: string;
            lines: string;
            light: string;
            fontDark: string;
            fontMedium: string;
            fontLight: string;
            warning: string;
        };
        iframe: boolean;
        helpers: {
            boxShadow: (color: string) => string;
            transparancy: (color: string, index: number) => string;
            shades: (color: string, index: number) => string;
            getWarningColor: (color: string) => string;
        };
        grayBoxShadow: string;
    }
}

// Set theme values
const defaultTheme: DefaultTheme = {
    fontFamily: `'Proxima Vara', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    colors: {
        primary: '#068cfc',
        lines: '#eaeef2',
        light: '#f2f4f6',
        fontDark: '#303944',
        fontMedium: '#6C7F8E',
        fontLight: '#9baab6',
        warning: '#FC5958',
    },
    iframe: false,
    helpers: {
        boxShadow: (color: string) => {
            const opacityColor = rgba(color, 0.35);
            return `0 0 25px -5px ${opacityColor}`;
        },
        transparancy: (color: string, index: number) => {
            return transparentize(index, color);
        },
        shades: (color: string, index: number) => {
            if (index < 0) return lighten(index * -0.1, color);
            else return darken(index * 0.1, color);
        },
        getWarningColor: (color: string) => {
            return setHue(0, color);
        },
    },
    grayBoxShadow: '0px 0.2px 0.3px hsl(0deg 0% 0% / 0.06), 0px 4.3px 6.2px -0.4px hsl(0deg 0% 0% / 0.16)',
};

// Set global styles
const GlobalStyle = createGlobalStyle`
  * { 
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    /* letter-spacing: -0.5px; */
  }

  body {
    font-family: ${defaultTheme.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    color: ${defaultTheme.colors.fontDark};
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-kerning: normal;
    font-optical-sizing: none;
    font-variant-ligatures: common-ligatures discretionary-ligatures;
    font-variant-numeric: proportional-nums;
    // font-synthesis: none;
   
    min-height: 100vh;

    /* * {
        border: 1px solid green;
    } */
  }

  img {
       width: 100%;
       height: auto;
       display: block;
   }
   
   //Icons
  .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
`;

export { defaultTheme, GlobalStyle };
