import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/Icon';

interface DropDownProps {
    align?: 'left' | 'right';
    onChange: (editionId: string) => void;
    options: DropDownOption[];
    value?: string;
}

type DropDownOption = {
    label: string;
    value: string;
};

export const DropDown: React.FC<DropDownProps> = (props) => {
    const { options, value, children, onChange } = props;
    const [open, setOpen] = useState(false);
    const dropDownEl = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen(!open);
    };
    const handleOptionClick = (option: DropDownOption) => {
        setOpen(false);
        onChange(option.value);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setOpen(false);
        }
    };

    const handleOutsideClick = (ev: MouseEvent) => {
        if (dropDownEl.current && !dropDownEl.current.contains(ev.target as Element)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.body.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <StyledDropdown ref={dropDownEl}>
            <StyledDropDownLabel onClick={handleToggle}>{children}</StyledDropDownLabel>
            {open && (
                <StyledList>
                    {options.map((option) => (
                        <StyledListItem
                            key={option.value}
                            onClick={() => handleOptionClick(option)}
                            selected={option.value === value}
                        >
                            {option.label}
                            {option.value === value && (
                                <StyledCheck>
                                    <Icon name="check-fill" />
                                </StyledCheck>
                            )}
                        </StyledListItem>
                    ))}
                </StyledList>
            )}
        </StyledDropdown>
    );
};

const StyledDropdown = styled.div`
    position: relative;
`;

const StyledDropDownLabel = styled.div`
    cursor: pointer;
`;

const StyledList = styled.ul`
    display: block;
    list-style: none;
    color: ${(props) => props.theme.colors.fontMedium};
    background-color: #fff;
    box-shadow: ${(props) => props.theme.grayBoxShadow};
    border-radius: 0.25rem;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    border: 1px solid ${(props) => props.theme.colors.lines};
`;

const StyledListItem = styled.li<{ selected: boolean }>`
    display: block;
    padding: 0.75rem 2rem 0.75rem 1rem;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    transition: all 300ms ease;

    &:not(:last-of-type) {
        border-bottom: 1px solid ${(props) => props.theme.colors.lines};
    }

    ${(props) =>
        props.selected &&
        css`
            font-weight: 800;
            pointer-events: none;
        `}

    &:hover {
        font-weight: 700;
    }

    &:last-child {
    }
`;

const StyledCheck = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;
