import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

// import { useAppDispatch } from 'redux/hooks';
// import { setEditionError } from 'redux/slices/storeSlice';

import { CartButton } from 'components/Buttons';
import { AppLink } from 'components/Link';
import { EmptyCartModal } from 'components/modals/EmptyCartModal';

import { formatTimer } from 'lib/helpers';
import { TypeModeType } from 'types/EditionTypes';

interface GoToNextPageButtonProps {
    cartQuantity: number | undefined;
    fastLane: string | undefined;
    timeLeft: number;
    previewToken: string | undefined;
    channelUrl: string;
    iframe: boolean;
    typeMode: TypeModeType | null;
}

export const GoToNextPageButton: React.FC<GoToNextPageButtonProps> = (props) => {
    const { cartQuantity, fastLane, timeLeft, previewToken, channelUrl, iframe, typeMode } = props;

    // check if on Cart page
    const location = useLocation();
    const pathArr = location.pathname.split('/');
    const currentPage = pathArr[4];

    const [showEmptyCartModal, setShowEmptyCartModal] = useState<boolean>(false);
    const [fastLaneUrl, setFastLaneUrl] = useState(fastLane);

    useEffect(() => {
        const storeUrl = previewToken ? `${channelUrl}&preview_token=${previewToken}` : channelUrl;
        setFastLaneUrl(`${fastLane}?s=${encodeURIComponent(storeUrl)}`);
    }, [channelUrl, previewToken]);

    const toFastLaneClickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!cartQuantity) {
            e.preventDefault();
            setShowEmptyCartModal(true);
        }
    };

    if (typeMode === 'split') {
        return (
            <>
                <StyledGoToNextPageButton>
                    {(!currentPage || currentPage === '' || currentPage === 'shows') && (
                        <AppLink to="/extras">
                            <CartButton
                                goToPage="extras"
                                remainingTime={formatTimer(timeLeft)}
                                cartQuantity={cartQuantity}
                            />
                        </AppLink>
                    )}
                    {currentPage === 'extras' && (
                        <AppLink to="/cart">
                            <CartButton
                                goToPage="cart"
                                remainingTime={formatTimer(timeLeft)}
                                cartQuantity={cartQuantity}
                            />
                        </AppLink>
                    )}
                    {(currentPage === 'info' || currentPage === 'support') && (
                        <AppLink to="/">
                            <CartButton
                                goToPage="shop"
                                remainingTime={formatTimer(timeLeft)}
                                cartQuantity={cartQuantity}
                            />
                        </AppLink>
                    )}
                    {currentPage === 'cart' && (
                        <a
                            href={fastLaneUrl}
                            target={iframe ? '_parent' : '_self'}
                            rel="noreferrer"
                            onClick={toFastLaneClickHandler}
                        >
                            <CartButton
                                goToPage="checkout"
                                remainingTime={formatTimer(timeLeft)}
                                cartQuantity={cartQuantity}
                            />
                        </a>
                    )}
                    <EmptyCartModal showModal={showEmptyCartModal} closeModal={() => setShowEmptyCartModal(false)} />
                </StyledGoToNextPageButton>
            </>
        );
    }

    return (
        <>
            <StyledGoToNextPageButton>
                {(currentPage === 'info' || currentPage === 'support') && !cartQuantity && (
                    <AppLink to="/">
                        <CartButton goToPage="shop" remainingTime={formatTimer(timeLeft)} cartQuantity={cartQuantity} />
                    </AppLink>
                )}
                {(currentPage === 'info' || currentPage === 'support') && !!cartQuantity && (
                    <AppLink to="/cart">
                        <CartButton goToPage="cart" remainingTime={formatTimer(timeLeft)} cartQuantity={cartQuantity} />
                    </AppLink>
                )}
                {(!currentPage || currentPage === '' || currentPage === 'shows') && (
                    <AppLink to="/cart">
                        <CartButton goToPage="cart" remainingTime={formatTimer(timeLeft)} cartQuantity={cartQuantity} />
                    </AppLink>
                )}
                {currentPage === 'cart' && (
                    <a
                        href={fastLaneUrl}
                        target={iframe ? '_parent' : '_self'}
                        rel="noreferrer"
                        onClick={toFastLaneClickHandler}
                    >
                        <CartButton
                            goToPage="checkout"
                            remainingTime={formatTimer(timeLeft)}
                            cartQuantity={cartQuantity}
                        />
                    </a>
                )}
                <EmptyCartModal showModal={showEmptyCartModal} closeModal={() => setShowEmptyCartModal(false)} />
            </StyledGoToNextPageButton>
        </>
    );
};

const StyledGoToNextPageButton = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.75rem;

    a {
        text-decoration: none;
        display: block;

        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
        }
    }
`;
