import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { reduxStore } from './redux/store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import './i18n';

const devMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

Sentry.init({
    dsn: devMode ? '' : 'https://66c3eb7f6cef4c4cae08592bb3af0e06@o543793.ingest.sentry.io/6489814',
});

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={null}>
            <Provider store={reduxStore}>
                <App />
            </Provider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
);
