import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { fetchEdition, setEditionError } from 'redux/slices/storeSlice';

import { FullScreenError } from 'components/modals/FullScreenError';
import { useFBPixel } from 'hooks/useFBPixel';
import { useTikTokPixel } from 'hooks/useTikTokPixel';

export const Edition = () => {
    const { event, edition, editionError } = useAppSelector((state) => {
        return { event: state.store.event, edition: state.store.edition, editionError: state.store.editionError };
    });
    const dispatch = useAppDispatch();
    const { lang, editionUri, channelUri } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const cookies = new Cookies();

    useFBPixel(event, edition);
    useTikTokPixel(event, edition);

    const entryUrl = window.location.href;

    interface cartCookieInterface {
        id: string;
        edition: string;
        channel: string;
        preview_token?: string;
        password?: string;
    }

    // Fetch edition data
    useEffect(() => {
        // Abort if editionUri is not set
        if (!editionUri) return;
        // Check if the cart cookie exists and matches the requested url and optional channel
        const cartCookie = cookies.get('cart');
        if (cartCookie && (cartCookie.edition !== editionUri || (channelUri && channelUri !== cartCookie.channel))) {
            // If not, ask user for confirmation to cancel existing session or continue
            dispatch(setEditionError({ message: 'Different edition', error: 'different_edition' }));
        } else {
            // Fetch the edition
            dispatchEdition(cartCookie);
        }
    }, [event, lang, editionUri, channelUri]);

    // Helper function to do the effective dispatching
    const dispatchEdition = (cartCookie: cartCookieInterface) => {
        const searchParams = new URLSearchParams(location.search);
        const previewToken = searchParams.get('preview_token') ?? undefined;
        const accessToken = searchParams.get('access_token') ?? undefined;
        const requestId = searchParams.get('request_id') ?? undefined;
        const password = cartCookie?.password;

        if (!event || !lang || !editionUri) return;
        const auth = checkAuthentication();
        dispatch(
            fetchEdition(lang, editionUri, entryUrl, channelUri, previewToken, accessToken, password, requestId, auth),
        );
    };

    // Navigate to the default channel of the edition if channelUri is empty
    useEffect(() => {
        if (edition && edition.store_tag) {
            const tag = atob(edition.store_tag);
            const range = document.createRange();
            const documentFragment = range.createContextualFragment(tag);
            document.body.appendChild(documentFragment);
        }
        if (edition && !channelUri) {
            const { search } = location;
            navigate(`/${lang}/${editionUri}/${edition.channel.uri}${search}`, { replace: true });
            dispatch(setEditionError(undefined));
        }
    }, [edition]);

    // Navigate if channelUri or EditionUri are not valid
    useEffect(() => {
        const { search } = location;

        switch (editionError?.error) {
            case 'invalid_channel':
                navigate(`/${lang}/${editionUri}${search}`, { replace: true });
                dispatch(setEditionError(undefined));
                break;
            case 'invalid_edition':
                navigate(`/${lang}${search}`, { replace: true });
                dispatch(setEditionError(undefined));
                break;
        }
    }, [editionError]);

    const checkAuthentication = () => {
        const searchParams = new URLSearchParams(location.search);
        let authenticationHeaders = undefined;
        // CERA
        if (searchParams.get('vn') && searchParams.get('ve') && searchParams.get('fn') && searchParams.get('ln')) {
            authenticationHeaders = {
                vennotennummer: searchParams.get('vn'),
                email: searchParams.get('ve'),
                firstname: searchParams.get('fn'),
                lastname: searchParams.get('ln'),
            };
        }
        return authenticationHeaders;
    };

    return (
        <>
            {edition && !editionError && (
                <>
                    <Outlet />
                </>
            )}
            {editionError && <FullScreenError editionError={editionError} event={event} search={location.search} />}
        </>
    );
};
